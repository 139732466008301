import React from 'react';
import { Flex, Text, Button, Box } from 'rebass';
import { Modal } from 'antd';

import theme from '../../theme';

type Props = {
  embedLink: string;
  handleCopy: (text: string) => void;
  onClose: () => void;
};

const EmbedModal = ({ embedLink, handleCopy, onClose }: Props) => {
  const codeContent = `<iframe width="560" height="315" frameborder="0" src=${embedLink} ></iframe>`;
  return (
    <Modal
      open={!!embedLink}
      onCancel={() => onClose()}
      width={685}
      bodyStyle={{ paddingLeft: 10, paddingRight: 10 }}
      footer={null}
    >
      <Flex width={1} flexDirection="column" alignItems="center" px={[0, 40]}>
        <Text as="h3" my={4} sx={{ ...theme.styles.h3 }}>
          Embed flow on your website
        </Text>
        <Box
          width={1}
          height={315}
          mb={3}
          sx={{
            boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
            borderRadius: 6,
            overflow: 'hidden',
          }}
        >
          <iframe width="100%" height="100%" frameBorder="0" src={embedLink}></iframe>
        </Box>
        <Box
          width={1}
          bg={theme.colors.modes.dark.background}
          p={3}
          sx={{
            borderRadius: 4,
            color: theme.colors.smokeLighten20,
            fontWeight: 'normal',
            fontSize: 1,
          }}
        >
          {codeContent}
        </Box>
        <Button width={1} my={4} height={50} fontSize={2} onClick={() => handleCopy(codeContent)}>
          Copy code
        </Button>
      </Flex>
    </Modal>
  );
};

export default EmbedModal;
