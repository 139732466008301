import React, { PureComponent } from 'react';
import { Flex, Text, Button, Box, Image, Link as RebassLink } from 'rebass';
import { History } from 'history';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { AuthPage, Infos } from '../../types/common';
import idleWebm from '../../assets/videos/idle.webm';
import idleMp4 from '../../assets/videos/idle.mp4';
import hoverWebm from '../../assets/videos/hover.webm';
import hoverMp4 from '../../assets/videos/hover.mp4';
import thumbnail from '../../assets/videos/thumbnail.png';
import { WALDO_SESSIONS_URL } from '../../utils/constants';
import theme from '../../theme';

type Props = RouteComponentProps & {
  infos: Infos;
  history: History;
  loggedIn: boolean;
  navigateTo: (page: AuthPage) => void;
};

type OwnState = {
  idleMode: boolean;
};

class Header extends PureComponent<Props, OwnState> {
  state = {
    idleMode: false,
  };

  videoRef?: HTMLVideoElement;

  setVideoRef = (ref: HTMLVideoElement) => {
    this.videoRef = ref;
  };

  onVideoEnd = () => {
    this.setState({ idleMode: true });
    if (this.videoRef) {
      this.videoRef.play();
    }
  };

  onClick = () => this.props.navigateTo('signup');

  renderVideos = () => (
    <Box width={400} height={400} minWidth={400} sx={{ position: 'relative', display: ['none', 'none', 'block'] }}>
      <Image
        src={thumbnail}
        sx={{ position: 'absolute', left: 0, top: 0, zIndex: 1, width: '100%' }}
        alt="video-thumbnail"
      />
      <video
        style={{ position: 'absolute', left: 0, top: 0, zIndex: !this.state.idleMode ? 3 : -1, width: '100%' }}
        onEnded={this.onVideoEnd}
        autoPlay={!this.state.idleMode}
        preload="none"
        muted
      >
        <source src={hoverMp4} type="video/mp4" />
        <source src={hoverWebm} type="video/webm" />
      </video>
      <video
        style={{ position: 'absolute', left: 0, top: 0, zIndex: 2, width: '100%' }}
        ref={(ref: HTMLVideoElement) => this.setVideoRef(ref)}
        loop
        preload="auto"
        muted
      >
        <source src={idleMp4} type="video/mp4" />
        <source src={idleWebm} type="video/webm" />
      </video>
    </Box>
  );

  renderDashboardCell = (value: number, text: string, border = false) => {
    const val: string = value === -1 ? '-' : `${value}`;
    return (
      <Flex
        width={1}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={
          border
            ? {
                borderLeftStyle: 'solid',
                borderRightStyle: 'solid',
                borderWidth: 1,
                borderColor: theme.colors.smokeLighten90,
              }
            : undefined
        }
      >
        <Text sx={{ fontWeight: 'bold', fontSize: 22, color: theme.colors.primary }}>{val}</Text>
        <Text mt={-2} sx={{ fontSize: 1, color: theme.colors.smokeDarken20 }}>
          {text}
        </Text>
      </Flex>
    );
  };

  renderDashboard = () => {
    const { applicationsCount, flowsCount, stepsCount } = this.props.infos;
    return (
      <Flex
        width={[300, 350, 586]}
        height={80}
        bg={theme.colors.white}
        sx={{
          transform: 'translateY(-75px)',
          borderRadius: 6,
          boxShadow: '0px 17.7515px 47.3372px rgba(22, 27, 45, 0.1)',
          borderBottomColor: theme.colors.primary,
          borderBottomStyle: 'solid',
          borderBottomWidth: 3,
          zIndex: 5,
        }}
      >
        <Flex width={1}>
          {this.renderDashboardCell(applicationsCount, 'Apps')}
          {this.renderDashboardCell(flowsCount, 'Flows', true)}
          {this.renderDashboardCell(stepsCount, 'Steps')}
        </Flex>
      </Flex>
    );
  };

  render = () => {
    return (
      <Flex flexDirection="column" alignItems="center">
        <Flex
          width={[95 / 100, 9 / 10]}
          mb={4}
          mt={5}
          flexDirection="column"
          bg={theme.colors.primaryVideo}
          mx="auto"
          sx={{ borderRadius: 10 }}
        >
          <Flex width={[1, 8 / 10]} m="auto" justifyContent="space-between" alignItems="center">
            <Flex flexDirection="column" maxWidth={500} pt={[40, 100]} pb={[70, 100]} px={[20, 0]}>
              <Text as="h1" mb={3} sx={{ fontWeight: 'bold', fontSize: [30, 40], lineHeight: '48px' }}>
                {'Introducing Sessions: the latest product from Waldo!'}
              </Text>
              <Text mb={1} sx={{ fontSize: 2 }}>
                {'🏃‍♀️ Run your mobile app on any device right from the browser'}
              </Text>
              <Text mb={1} sx={{ fontSize: 2 }}>
                {'🕵️ Inspect and annotate in context'}
              </Text>
              <Text mb={3} sx={{ fontSize: 2 }}>
                {'🔗 Share the replay link with anyone on your team'}
              </Text>
              <Flex justifyContent={['center', 'flex-start']}>
                <RebassLink href={WALDO_SESSIONS_URL}>
                  <Button mr={4} width="fit-content">
                    Start a Session
                  </Button>
                </RebassLink>
              </Flex>
            </Flex>
            {this.renderVideos()}
          </Flex>
        </Flex>
        {this.renderDashboard()}
      </Flex>
    );
  };
}

export default withRouter(Header);
