import React, { ReactNode } from 'react';
import { Text, Flex } from 'rebass';

import { CATEGORY_META } from '../../utils/constants';

type Props = {
  color?: string;
  fontWeight?: number | string;
  text: string;
  textColor?: string;
  ml?: any;
  mb?: any;
  mt?: any;
  children?: ReactNode;
};

const Badge = ({ color, children, fontWeight, text, textColor, ml, mb, mt }: Props) => {
  const { color: categoryTextColor, bg } = CATEGORY_META[text] || CATEGORY_META.Default;
  return (
    <Flex
      ml={ml}
      mb={mb}
      mt={mt}
      width="fit-content"
      alignItems="center"
      justifyContent="center"
      sx={{ borderRadius: 5, overflow: 'hidden' }}
    >
      <Flex bg={color || bg}>
        <Text
          as="span"
          px={3}
          py={1}
          sx={{
            fontSize: 0,
            fontWeight: fontWeight || 'normal',
            color: textColor || categoryTextColor,
          }}
        >
          {children}
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Badge;
