import { Dispatch } from 'redux';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Box, Text, Flex } from 'rebass';
import { match } from 'react-router-dom';
import { History } from 'history';
import { Modal } from 'antd';
import * as _ from 'lodash';

import ActionBuilder from '../../store/actions';
import { AppState, Flow, InitialActionParams, AuthPage, User } from '../../types/common';
import ActionBoxContainer from './ActionBoxContainer';
import PoweredBy from '../../components/client/PoweredBy';
import Share from '../../components/client/Share';
import FlowComponent from '../../components/common/FlowComponent';
import theme from '../../theme';
import { RouteParser, RouteFormatter } from '../../utils/routes';
import Line from '../../components/client/Line';
import { formatDate } from '../../utils';
import Loader from '../../components/common/Loader';
import { generateQuery } from '../../utils/flows';
import FlowHeader from '../../components/client/FlowHeader';

type Params = {
  appName: string;
  applicationId: string;
  appFlowName: string;
  appFlowId: string;
  versionFlowId?: string;
};

type InitProps = {
  location: any;
};

type Props = InitProps & {
  dispatch: Dispatch;
  embed: (link: string) => void;
  history: History;
  match: match<Params>;
  navigateTo: (page?: AuthPage) => void;
  onDownload: (flow: Flow) => void;
  flows?: Flow[];
  updateErrorMessage: (message: string) => void;
  updateSuccessMessage: (message: string) => void;
  user: User | null;
};

class ApplicationContainer extends PureComponent<Props> {
  static initialAction = async ({ url }: InitialActionParams) => {
    const defaultResult = Promise.resolve();
    if (!url) {
      return defaultResult;
    }
    const { appFlowId } = RouteParser.flowRoute(url);
    return ActionBuilder.getFlows({ appFlowId });
  };

  loading = false;

  componentDidMount = () => {
    this.checkForNotFound();
    this.fetchResources();
  };

  componentDidUpdate = (prevProps: Props) => {
    this.checkForNotFound();
    if (prevProps.location !== this.props.location) {
      this.fetchResources();
    }
  };

  checkForNotFound = () => {
    const { flows, history } = this.props;
    if (_.isEqual(flows, [])) {
      history.push(RouteFormatter.notFoundRoute());
    }
  };

  fetchResources = async () => {
    const {
      dispatch,
      location: { pathname },
    } = this.props;
    const action = await ApplicationContainer.initialAction({ url: pathname });
    if (action) {
      dispatch(action);
    }
  };

  renderSeparator = () => (
    <Flex width={1} px={5} alignItems="center" mb={4}>
      <Line color={theme.colors.pearl} />
      <Text as="h2" width={1 / 2} sx={{ ...theme.styles.h4, textAlign: 'center', color: theme.colors.pearl }}>
        OLDER VERSIONS
      </Text>
      <Line color={theme.colors.pearl} />
    </Flex>
  );

  renderFlow = (flow: Flow, index = 0, inModal = false) => {
    const { embed, updateSuccessMessage, onDownload } = this.props;
    const {
      application: { id: applicationId, name: applicationName },
      appFlow: { id: appFlowId, name: appFlowName },
      id: versionFlowId,
      version: { createdAt },
    } = flow;
    const displayDate = formatDate(createdAt);
    const {
      match: {
        params: { versionFlowId: routeVersionFlowId },
      },
    } = this.props;
    return (
      <Flex width={1} flexDirection="column" key={flow.id}>
        {index === 1 && this.renderSeparator()}
        <Flex
          width={1}
          justifyContent="space-between"
          alignItems={['flex-start', 'center']}
          mb={3}
          ml={[1, 0]}
          flexDirection={['column', 'row']}
        >
          <Flex pl={1} flexDirection="column" mb={inModal ? [0, '-40px'] : 0}>
            <Text alignSelf="flex-end" sx={{ ...theme.styles.h3, fontWeight: 'regular' }}>{`${
              index === 0 ? 'Latest' : 'Version'
            } | ${flow.version.semVer}`}</Text>
            <Text sx={{ fontWeight: 'regular', color: theme.colors.smokeLighten20, fontSize: 2 }}>{displayDate}</Text>
          </Flex>
          <Share flow={flow} updateSuccessMessage={updateSuccessMessage} onDownload={onDownload} embed={embed} />
        </Flex>
        <FlowComponent
          flow={flow}
          link={
            routeVersionFlowId
              ? undefined
              : RouteFormatter.versionFlowRoute(applicationName, applicationId, appFlowName, appFlowId, versionFlowId)
          }
          mode={inModal ? 'modal' : 'flow'}
          responsive={!!routeVersionFlowId}
        />
      </Flex>
    );
  };

  renderFlows = (flows: Flow[]) =>
    flows.length > 0 ? (
      <Box width={1} mb={5}>
        {flows.map((flow, index) => this.renderFlow(flow, index, false))}
      </Box>
    ) : (
      <Loader height="50vh" />
    );

  renderActionBox = () => (
    <Box width={1}>
      <ActionBoxContainer />
      <Box width={1} mt={80} mb={4}>
        <PoweredBy />
      </Box>
    </Box>
  );

  renderFlowModal = () => {
    const {
      flows = [],
      match: {
        params: { appName, applicationId, appFlowName, appFlowId, versionFlowId },
      },
      history,
    } = this.props;
    const flow = flows.find(({ id }) => id === versionFlowId);
    if (!flow) {
      return null;
    }
    const onClose = () => history.push(RouteFormatter.flowRoute(appName, applicationId, appFlowName, appFlowId));
    return (
      <Modal
        onCancel={onClose}
        open={!!flow}
        width="90%"
        footer={null}
        bodyStyle={{ paddingTop: `32px 10px 48px 10px` }}
      >
        <Box px={[0, 40]}>
          <FlowHeader flow={flow} />
          {this.renderFlow(flow, 0, true)}
        </Box>
      </Modal>
    );
  };

  render = () => {
    const { flows } = this.props;
    return (
      <Box width={[95 / 100, 8 / 10]} m="auto" pt={6}>
        {flows && flows.length > 0 && <FlowHeader flow={flows[0]} />}
        {this.renderFlows(flows || [])}
        {this.renderActionBox()}
        {this.renderFlowModal()}
      </Box>
    );
  };
}

const mapStateToProps = (state: AppState, props: InitProps) => {
  const {
    location: { pathname },
  } = props;
  // Here we don't extract appFlowId and appFlowId from params because this also runs server side
  const { appFlowId, versionFlowId } = RouteParser.versionFlowRoute(pathname);
  const request = generateQuery({ appFlowId });
  const flows = state.flowRequests[request];
  const filteredFlows = versionFlowId && flows ? flows.filter(({ id }) => id === versionFlowId) : flows;
  return {
    flows: filteredFlows,
  };
};

export default connect(mapStateToProps)(ApplicationContainer);
