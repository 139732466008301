import React, { PureComponent } from 'react';
import { Box, Flex, Text } from 'rebass';
import { Label, Input } from '@rebass/forms';

import theme from '../../theme';
import { redirectTo } from '../../utils';
import { AuthApi } from '../../api/api';
import CustomButton from '../common/CustomButton';

type OwnState = {
  email: string;
  password: string;
  error: string;
};

class AdminLogin extends PureComponent<Record<string, never>, OwnState> {
  state = {
    email: '',
    password: '',
    error: '',
  };

  formRef?: HTMLFormElement;

  setFormRef = (ref: HTMLFormElement) => {
    if (ref) {
      this.formRef = ref;
    }
  };

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value });

  onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value });

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!this.formRef) return;
    const valid = this.formRef.reportValidity();
    if (valid) {
      await this.onSubmit();
    }
  };

  onSubmit = async () => {
    this.setState({ error: '' });
    const { email, password } = this.state;
    try {
      await AuthApi.adminLogin(email, password);
      // redirect user to main view
      redirectTo('/');
    } catch (err) {
      this.setState({ error: 'Invalid email or password' });
    }
  };

  render = () => {
    return (
      <Flex width={1} height="100vh" justifyContent="center" alignItems="center">
        <Flex
          width={300}
          height={270}
          p={3}
          flexDirection="column"
          justifyContent="space-between"
          sx={{ borderStyle: 'solid', borderWidth: 2, borderColor: theme.colors.gray, borderRadius: 10 }}
          as="form"
          ref={this.setFormRef}
        >
          {this.state.error && (
            <Text as="p" color="red" fontSize={1}>
              {this.state.error}
            </Text>
          )}
          <Box>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              onChange={this.onEmailChange}
              placeholder="jane@example.com"
              required
              type="email"
              variant="input"
            />
          </Box>
          <Box>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              name="password"
              onChange={this.onPasswordChange}
              placeholder="••••••••"
              required
              type="password"
              variant="input"
            />
          </Box>
          <Flex px={2} justifyContent="center">
            <CustomButton onClick={this.handleSubmit}>Login</CustomButton>
          </Flex>
        </Flex>
      </Flex>
    );
  };
}

export default AdminLogin;
