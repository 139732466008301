import cookie from 'cookie';

import { ADMIN_COOKIE_NAME, CLIENT_COOKIE_NAME } from './constants';

export const getAdminToken = () => cookie.parse(document.cookie)[ADMIN_COOKIE_NAME];

export const getToken = () => cookie.parse(document.cookie)[CLIENT_COOKIE_NAME];

export const deleteAdminCookie = () => {
  // Set cookie expiration date in the past to remove it
  document.cookie = `${ADMIN_COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
};

export const deleteClientCookie = () => {
  // Set cookie expiration date in the past to remove it
  document.cookie = `${CLIENT_COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
};
