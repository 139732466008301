import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { Switch, Route, Link, withRouter, RouteComponentProps } from 'react-router-dom';

import NavbarAdminContainer from '../../containers/admin/AdminNavbarContainer';
import ToDoContainer from '../../containers/admin/AdminToDoContainer';
import AdminAppsContainer from '../../containers/admin/AdminAppsContainer';
import theme from '../../theme';
import NotFound404 from '../common/NotFound404';
import { LEFT_BANNER_WIDTH } from '../../utils/constants';
import AdminApplicationContainer from '../../containers/admin/AdminApplicationContainer';

const linkStyle = {
  textDecoration: 'None',
  color: theme.colors.primary,
  paddingLeft: 16,
  paddingTop: 6,
  paddingBottom: 6,
};

type Props = RouteComponentProps & {
  adminloggedIn: boolean;
};

const AdminLayout = (props: Props) => {
  const {
    adminloggedIn,
    location: { pathname },
  } = props;
  if (!adminloggedIn) {
    return null;
  }

  const renderNavigation = () => (
    <Flex
      width={LEFT_BANNER_WIDTH}
      minHeight="100%"
      pt={3}
      flexDirection="column"
      sx={{
        borderRightWidth: 1,
        borderRightColor: theme.colors.gray,
        borderRightStyle: 'solid',
      }}
    >
      <Text pl={3} as="h5">
        MENU
      </Text>
      <Link
        to="/"
        style={{ ...linkStyle, backgroundColor: pathname === '/' ? theme.colors.smokeLighten90 : 'transparent' }}
      >
        To do
      </Link>
      <Link
        to="/apps"
        style={{
          ...linkStyle,
          backgroundColor: pathname.startsWith('/apps') ? theme.colors.smokeLighten90 : 'transparent',
        }}
      >
        iOS Apps
      </Link>
    </Flex>
  );

  const renderContent = () => (
    <Box width={1} bg={theme.colors.smokeLighten90}>
      <Switch>
        <Route exact path="/apps/:applicationId/:versionId" component={AdminApplicationContainer} />
        <Route exact path="/apps" component={AdminAppsContainer} />
        <Route exact path="/:pageNumber(\d+)?" component={ToDoContainer} />
        <Route component={NotFound404} />
      </Switch>
    </Box>
  );

  return (
    <Flex width={1} minHeight="100%" flexDirection="column">
      <NavbarAdminContainer />
      <Flex width={1} flex="auto">
        {renderNavigation()}
        {renderContent()}
      </Flex>
    </Flex>
  );
};

export default withRouter(AdminLayout);
