import React, { PureComponent } from 'react';
import { ThemeProvider } from 'theme-ui';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import AdminAuthSwitch from './components/admin/AdminAuthSwitch';
import LayoutContainer from './containers/client/LayoutContainer';
import theme from './theme';
import 'antd/dist/antd.css';
import { pathToMeta } from './utils/pages';

type Props = RouteComponentProps & {
  adminloggedIn: boolean;
  isAdmin: boolean;
};

class App extends PureComponent<Props> {
  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.location !== this.props.location) {
      window.scrollTo(0, 0);
      const { title } = pathToMeta(this.props.location.pathname);
      document.title = title;
    }
  };

  render = () => {
    const { adminloggedIn, isAdmin } = this.props;
    return (
      <ThemeProvider theme={theme}>
        {isAdmin ? <AdminAuthSwitch adminloggedIn={adminloggedIn} /> : <LayoutContainer />}
      </ThemeProvider>
    );
  };
}

export default withRouter(App);
