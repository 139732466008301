import React, { PureComponent } from 'react';
import { Flex, Text, Button, Link as RebassLink } from 'rebass';
import { Switch } from '@rebass/forms';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { DownOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { Flow, FlowStatus, PatchFlowRequest, PatchAppFlow } from '../../types/common';
import FlowComponent from '../../components/common/FlowComponent';
import { getAdminToken } from '../../utils/token';
import ActionBuilder from '../../store/actions';
import theme from '../../theme';
import { RouteFormatter, getAppHostname } from '../../utils/routes';
import { Input } from '@rebass/forms';

type Props = {
  flow: Flow;
  updateFlow: (patchFlowRequest: PatchFlowRequest) => void;
  patchAppFlow: (appFlowId: string, update: PatchAppFlow) => void;
};

type OwnState = {
  tagInputs: string[];
  canApprove: boolean;
  canUpdate: boolean;
  canEditName: boolean;
  newAppFlowName: string;
};

class AdminFlowContainer extends PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    const {
      interactions,
      appFlow: { name: appFlowName },
    } = this.props.flow;
    this.state = {
      tagInputs: interactions.map((i) => i.tags[0]),
      canApprove: false,
      canUpdate: false,
      canEditName: false,
      newAppFlowName: appFlowName,
    };
  }

  componentDidMount = () => {
    this.updateDisplayedButtons();
  };

  componentDidUpdate = () => {
    this.updateDisplayedButtons();
  };

  updateDisplayedButtons = () => {
    const canApprove = this.state.tagInputs.every((item) => !!item && item !== '');
    if (canApprove !== this.state.canApprove) {
      this.setState({ canApprove });
    }
    return canApprove;
  };

  togglePublish = async (inputStatus?: FlowStatus) => {
    const {
      flow: { id, interactions, status },
      updateFlow,
    } = this.props;
    let computedStatus: FlowStatus = 'invalid';
    const newInteractions = interactions.map((item, index) => ({
      ...item,
      tags: [this.state.tagInputs[index]],
    }));
    if (status === 'valid') {
      computedStatus = 'invalid';
    } else if (status === 'invalid') {
      computedStatus = 'valid';
    } else if (status === 'needs_validation') {
      if (!this.state.canApprove) {
        return;
      }
      computedStatus = 'valid';
    }
    this.setState({ canUpdate: false });
    await updateFlow({ id, interactions: newInteractions, status: inputStatus || computedStatus });
  };

  toggleFeatured = async () => {
    const {
      flow: {
        appFlow: { id: appFlowId, featured: currentFeatured },
      },
      patchAppFlow,
    } = this.props;
    await patchAppFlow(appFlowId, { featured: !currentFeatured });
  };

  onChange = (index: number, value: string) => {
    this.setState((prevState) => ({
      canUpdate: true,
      tagInputs: prevState.tagInputs.map((item, i) => (i === index ? value : item)),
    }));
  };

  renderHeaderButtons = () => {
    const {
      flow: {
        status,
        appFlow: { featured },
      },
    } = this.props;
    let toggle;
    let approve;
    let update;
    if (status !== 'needs_validation') {
      toggle = (
        <Flex width={1} alignItems="center" flexDirection="column">
          <Flex width={1} alignItems="center">
            <Switch checked={featured} onClick={() => this.toggleFeatured()} sx={{ cursor: 'pointer' }} />
            <Text pl={2} fontWeight={500}>
              Featured
            </Text>
          </Flex>
          <Flex width={1} my={2} alignItems="center">
            <Switch checked={status === 'valid'} onClick={() => this.togglePublish()} sx={{ cursor: 'pointer' }} />
            <Text pl={2} fontWeight={500}>
              Published
            </Text>
          </Flex>
        </Flex>
      );
    }
    if (status === 'needs_validation') {
      const menu = (
        <Menu>
          <Menu.Item key="1" onClick={() => this.togglePublish('invalid')} icon={<EyeInvisibleOutlined />}>
            Approve but not publish
          </Menu.Item>
        </Menu>
      );
      approve = (
        <Dropdown.Button
          overlay={menu}
          disabled={!this.state.canApprove}
          placement="bottomRight"
          icon={<DownOutlined />}
          onClick={() => this.togglePublish()}
        >
          Approve and Publish
        </Dropdown.Button>
      );
    }
    if (status === 'valid' && this.state.canUpdate) {
      update = (
        <Button width={120} onClick={() => this.togglePublish('valid')}>
          Update
        </Button>
      );
    }
    return (
      <Flex width={1 / 3} justifyContent="center">
        {toggle}
        {approve}
        {update}
      </Flex>
    );
  };

  editName = () => this.setState({ canEditName: true });
  onEditName = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ newAppFlowName: e.target.value.trim() });
  saveName = async () => {
    const {
      flow: {
        appFlow: { id: appFlowId },
      },
    } = this.props;
    const { newAppFlowName } = this.state;
    await this.props.patchAppFlow(appFlowId, { name: newAppFlowName });
    this.setState({ canEditName: false });
  };

  renderAppFlowName = () => {
    const {
      flow: {
        application: { name: applicationName, id: applicationId },
        appFlow: { name: appFlowName },
        version: { id },
        status,
        userName,
        createdAt,
      },
    } = this.props;
    const linkPrefix = getAppHostname();
    const commonTextStyle = {
      ...theme.styles.h4,
      color: theme.colors.smokeDarken20,
      fontWeight: 400,
    };
    return (
      <Flex style={{ alignItems: 'center' }}>
        <RebassLink
          href={status === 'valid' ? `${linkPrefix}${RouteFormatter.appFlowNameRoute(appFlowName)}` : undefined}
          style={{ textDecoration: 'none' }}
        >
          <Text sx={{ ...theme.styles.h4 }}>{appFlowName}</Text>
        </RebassLink>
        <Text px={2} sx={commonTextStyle}>
          on
        </Text>
        <RouterLink to={RouteFormatter.adminApplicationRoute(applicationId, id)} style={{ textDecoration: 'none' }}>
          <Text sx={{ ...theme.styles.h4 }}>{applicationName}</Text>
        </RouterLink>
        {userName && (
          <Text px={2} sx={commonTextStyle}>
            {`recorded by ${userName}`}
          </Text>
        )}
        <Text px={2} sx={commonTextStyle}>
          {moment(createdAt).format('LLLL')}
        </Text>
        <Button
          sx={{
            ...theme.styles.h4,
            color: 'primary',
            backgroundColor: 'transparent',
            ':hover': {
              backgroundColor: 'transparent',
            },
            boxShadow: 'none',
          }}
          onClick={this.editName}
        >
          Rename
        </Button>
      </Flex>
    );
  };

  renderAppFlowNameInput = () => (
    <Flex width={1} alignItems="center">
      <Input
        maxWidth={200}
        height={42}
        id="flowName"
        name="flowName"
        type="text"
        onChange={this.onEditName}
        value={this.state.newAppFlowName}
      />
      <Button
        sx={{
          ...theme.styles.h3,
          color: 'primary',
          backgroundColor: 'transparent',
          ':hover': {
            backgroundColor: 'transparent',
          },
          boxShadow: 'none',
        }}
        onClick={this.saveName}
      >
        Save
      </Button>
    </Flex>
  );

  renderHeader = () => {
    const {
      flow: {
        version,
        status,
        application: { name: applicationName, id: applicationId },
        appFlow: { name: appFlowName, id: appFlowId },
      },
    } = this.props;
    const { canEditName } = this.state;
    const linkPrefix = getAppHostname();
    return (
      <Flex mb={3}>
        <Flex width={1} justifyContent="space-between" flexDirection="column">
          {canEditName ? this.renderAppFlowNameInput() : this.renderAppFlowName()}
          <RebassLink
            href={
              status === 'valid'
                ? `${linkPrefix}${RouteFormatter.flowRoute(applicationName, applicationId, appFlowName, appFlowId)}`
                : undefined
            }
            style={{ textDecoration: 'none' }}
          >
            <Text as="p" sx={{ ...theme.styles.p }}>
              Version {version.semVer}
            </Text>
          </RebassLink>
        </Flex>
        <Flex width={2 / 3} justifyContent="flex-end" alignItems="center">
          <RebassLink
            width={180}
            mr={5}
            href={RouteFormatter.waldoAppFlowLink(applicationId, appFlowId)}
            target="_blank"
            sx={{ textDecoration: 'none', color: theme.colors.primary, textAlign: 'right' }}
          >
            See on Waldo
          </RebassLink>
          {this.renderHeaderButtons()}
        </Flex>
      </Flex>
    );
  };

  render = () => {
    const { flow } = this.props;
    const adminProps = {
      onChange: this.onChange,
      tagInputs: this.state.tagInputs,
    };
    return (
      <Flex width={1} mb={5} flexDirection="column">
        {this.renderHeader()}
        <FlowComponent flow={flow} adminProps={adminProps} />
      </Flex>
    );
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateFlow: async (patchFlowRequest: PatchFlowRequest) =>
    dispatch(await ActionBuilder.updateFlow(patchFlowRequest, getAdminToken())),
  patchAppFlow: async (appFlowId: string, update: PatchAppFlow) =>
    dispatch(await ActionBuilder.patchAppFlow(appFlowId, update, getAdminToken())),
});

export default connect(null, mapDispatchToProps)(AdminFlowContainer);
