import logger from 'redux-logger';
import { applyMiddleware, createStore, Dispatch } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import { PRINT_STORE_UPDATES } from '../utils/constants';
import reducer from './reducers';
import { GetState } from '../types/common';

const stateInjectorMiddleware =
  ({ dispatch, getState }: { dispatch: Dispatch; getState: GetState }) =>
  (next: Dispatch) =>
  // eslint-disable-next-line @typescript-eslint/ban-types
  (action: Function) => {
    const state = getState();
    // If action is a function, inject dispatch and token
    if (typeof action === 'function') {
      // If function takes more than 1 argument,
      // inject dispatcher and token
      if (action.length > 1) {
        return action(dispatch, state);
      }

      // Otherwise inject dispatcher only
      return action(dispatch);
    }

    return next(action);
  };

const items = [stateInjectorMiddleware];

if (PRINT_STORE_UPDATES) {
  items.push(logger);
}

const middlewares = applyMiddleware(...items);

const configureStore = (initialState = {}) => createStore(reducer, initialState, composeWithDevTools(middlewares));

export default configureStore;
