import React, { PureComponent } from 'react';
import { Flex, Text, Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';

import { AuthPage } from '../../types/common';
import theme from '../../theme';
import { AuthApi } from '../../api/api';
import CustomButton from '../../components/common/CustomButton';

type Props = {
  navigateTo: (page?: AuthPage) => void;
};

type OwnState = {
  email: string;
  error: string;
};

class ForgotContainer extends PureComponent<Props, OwnState> {
  state = {
    email: '',
    error: '',
  };

  formRef?: HTMLFormElement;

  setFormRef = (ref: HTMLFormElement) => {
    if (ref) {
      this.formRef = ref;
    }
  };

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value });

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!this.formRef) return;
    const valid = this.formRef.reportValidity();
    if (valid) {
      await this.onSubmit();
    }
  };

  onSubmit = async () => {
    this.setState({ error: '' });
    const { email } = this.state;
    const { navigateTo } = this.props;
    try {
      await AuthApi.generateResetCode(email);
      navigateTo('reset-check');
    } catch (err) {
      this.setState({ error: 'Something went wrong' });
    }
  };

  renderForm = () => {
    const { email, error } = this.state;
    return (
      <Flex width={1} flexDirection="column" as="form" ref={this.setFormRef}>
        <Text mb={3} sx={{ ...theme.styles.p, color: theme.colors.red }}>
          {error}
        </Text>
        <Box width={1} mb={'24px'}>
          <Label mb={2} htmlFor="email" sx={{ fontWeight: 500, fontSize: 2 }}>
            Email address*
          </Label>
          <Input
            py={3}
            id="email"
            name="email"
            onChange={this.onEmailChange}
            placeholder="Enter email address"
            required
            type="email"
            value={email}
            variant="input"
          />
        </Box>
        <CustomButton onClick={this.handleSubmit}>Get a reset link</CustomButton>
      </Flex>
    );
  };

  render = () => (
    <Flex width={500} px={[3, 5]} py={[4, 5]} flexDirection="column" alignItems="center">
      <Text mb={3} sx={{ ...theme.styles.h1, fontWeight: 'bold' }}>
        Forgot password
      </Text>
      {this.renderForm()}
      <Button
        p={[0, 0]}
        variant="text"
        onClick={() => this.props.navigateTo('login')}
        sx={{ fontSize: 1, fontWeight: 500, alignSelf: 'center' }}
      >
        Back
      </Button>
    </Flex>
  );
}

export default ForgotContainer;
