import { Dispatch } from 'redux';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Flex, Text, Image, Link as RebassLink, Box } from 'rebass';
import { match } from 'react-router-dom';
import { History } from 'history';
import * as _ from 'lodash';

import ActionBuilder from '../../store/actions';
import {
  AppState,
  App,
  InitialActionParams,
  Action,
  Flow,
  Version as VersionType,
  FlowStatus,
} from '../../types/common';
import { getAdminToken } from '../../utils/token';
import { RouteParser, RouteFormatter } from '../../utils/routes';
import Loader from '../../components/common/Loader';
import CustomDropdown from '../../components/common/Dropdown';
import theme from '../../theme';
import Badge from '../../components/client/Badge';
import extLinkSrc from '../../assets/icons/external-link.svg';
import Description from '../../components/common/Description';
import { formatAppStoreUrl } from '../../utils';
import { sortVersions, getLatestVersion, applicationFlowsByVersion } from '../../utils/flows';
import Version from '../../components/client/Version';
import AdminFlowContainer from './AdminFlowContainer';
import { FLOW_STATUS } from '../../utils/constants';

type Params = {
  applicationId: string;
  versionId: string;
};

type InitProps = {
  location: any;
};

type Props = InitProps & {
  application?: App;
  appVersions: VersionType[];
  dispatch: Dispatch;
  flows: Flow[];
  history: History;
  match: match<Params>;
};

type State = {
  flowStatus: FlowStatus;
};

class AdminApplicationContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      flowStatus: FLOW_STATUS.VALID,
    };
  }

  static initialAction = ({ url, adminAuthToken }: InitialActionParams) => {
    const defaultResult = Promise.resolve([] as Action[]);
    if (!url) {
      return defaultResult;
    }
    const { applicationId } = RouteParser.adminApplicationRoute(url);
    return Promise.all([
      ActionBuilder.getAdminFlows(adminAuthToken),
      ActionBuilder.getAdminApplication(applicationId, adminAuthToken),
    ]);
  };

  componentDidMount = () => this.fetchResources();

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.versionId !== this.props.match.params.versionId) {
      this.fetchResources();
    }
  };

  fetchResources = async () => {
    const {
      dispatch,
      location: { pathname },
    } = this.props;
    const actions = await AdminApplicationContainer.initialAction({ url: pathname, adminAuthToken: getAdminToken() });
    actions.map(dispatch);
  };

  onStatusChange = (status: FlowStatus) => this.setState({ flowStatus: status });

  renderFlowStatusDropDown = () => {
    return (
      <CustomDropdown
        styleProps={{ px: 3, py: 3 }}
        values={Object.values(FLOW_STATUS)}
        onClick={(state: FlowStatus) => this.onStatusChange(state)}
      >
        <Text as="p" style={{ width: theme.space[theme.space.length - 3] }}>
          {this.state.flowStatus}
        </Text>
      </CustomDropdown>
    );
  };

  renderAppInfo = ({ name, appStoreId, latestVersion: { categories, description, identity } }: App) => (
    <Flex width={1} pl={3} pt={3}>
      <Flex width={600} pl={3} flexDirection="column">
        <Flex width={1} alignItems="center">
          <Text as="h2" pb={1} sx={{ ...theme.styles.h2 }}>
            {name}
          </Text>
          <Badge text={categories[0]} ml={3} />
        </Flex>
        <Box width={1}>
          <Description text={description} />
        </Box>
        <RebassLink
          mt={1}
          href={formatAppStoreUrl(name, appStoreId)}
          target="_blank"
          sx={{ ...theme.styles.h4, color: theme.colors.primary, width: 'fit-content' }}
        >
          {`© ${identity}`}
          <Image ml={1} width={10} height={10} src={extLinkSrc} alt="ext-link" />
        </RebassLink>
      </Flex>
    </Flex>
  );

  // semvers are sorted by version - so first one is the latest
  renderVersionComponent = (appVersion: VersionType, latest: boolean) => {
    const { semVer, id: versionId, createdAt } = appVersion;
    const {
      match: {
        params: { versionId: routeVersionId, applicationId },
      },
      history,
    } = this.props;
    const isSelected = (latest && routeVersionId === 'latest') || versionId === routeVersionId;
    const onClick = () => history.push(RouteFormatter.adminApplicationRoute(applicationId, versionId));
    return (
      <Version
        key={semVer}
        semVer={semVer}
        onClick={onClick}
        createdAt={createdAt}
        latest={latest}
        selected={isSelected}
      />
    );
  };

  renderVersions = (appVersions: VersionType[]) => (
    <Flex width={1} my={4} px={4} alignItems="center">
      <Text pb={3} mr={5} sx={{ ...theme.styles.h3 }}>
        Versions
      </Text>
      <Flex width={1} justifyContent="flex-start" alignItems="center">
        {sortVersions(appVersions).map((item, index) =>
          this.renderVersionComponent(item as VersionType, index === appVersions.length - 1),
        )}
      </Flex>
    </Flex>
  );

  renderHeader = ({ name, id }: App) => (
    <Flex
      width={1}
      height={64}
      px={4}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderBottomStyle: 'solid',
        borderBottomColor: theme.colors.gray,
        borderBottomWidth: 1,
      }}
    >
      <Text>{`Apps / ${name}`}</Text>
      <RebassLink
        href={RouteFormatter.waldoApplicationLink(id)}
        sx={{ textDecoration: 'none', color: theme.colors.primary }}
        target="_blank"
      >
        See on Waldo
      </RebassLink>
    </Flex>
  );

  renderFlow = (flow: Flow) => <AdminFlowContainer key={flow.id} flow={flow} />;

  getFlows = () => this.props.flows.filter((flow) => flow.status === this.state.flowStatus);

  renderFlows = (flows: Flow[]) => (
    <Box width={1} my={5} px={4}>
      {flows.map(this.renderFlow)}
    </Box>
  );

  render = () => {
    const { application, appVersions } = this.props;
    if (!application) {
      return <Loader />;
    }
    const renderedFlows = this.getFlows();
    return (
      <Flex width={1} flexDirection="column">
        {this.renderHeader(application)}
        {this.renderAppInfo(application)}
        {this.renderFlowStatusDropDown()}
        {appVersions.length > 0 && this.renderVersions(appVersions)}
        {renderedFlows.length > 0 && this.renderFlows(renderedFlows)}
      </Flex>
    );
  };
}

const mapStateToProps = (state: AppState, props: InitProps) => {
  const {
    location: { pathname },
  } = props;
  const { applicationId, versionId: rawVersionId } = RouteParser.adminApplicationRoute(pathname);
  let flows: Flow[] = [];
  let appVersions: VersionType[] = [];
  if (applicationId && typeof applicationId === 'string') {
    const applicationFlows = state.adminFlows.filter(({ application }) => application.id === applicationId);
    appVersions = _.uniqBy(
      applicationFlows.map(({ version }) => ({ ...version })),
      'semVer',
    );
    if (appVersions.length > 0 && applicationFlows && applicationFlows.length > 0) {
      const versionId = rawVersionId === 'latest' ? getLatestVersion(appVersions) : rawVersionId;
      if (versionId) {
        flows = applicationFlowsByVersion(applicationFlows, versionId);
      }
    }
  }
  return {
    application: state.adminApps.find(({ id }) => id === applicationId),
    appVersions,
    flows,
  };
};

export default connect(mapStateToProps)(AdminApplicationContainer);
