import { Dispatch } from 'redux';

import { UPDATE_ERROR_MESSAGE, UPDATE_SUCCESS_MESSAGE } from './actionTypes';

const NOTIF_DISPLAY_DURATION = 5000;

class NotifActionBuilder {
  errorTimeoudId: any;
  successTimeoutId: any;

  updateErrorMessage = (payload: any, dispatch: Dispatch) => {
    clearTimeout(this.errorTimeoudId);
    dispatch({
      type: UPDATE_ERROR_MESSAGE,
      payload,
    });
    this.errorTimeoudId = setTimeout(
      () =>
        dispatch({
          type: UPDATE_ERROR_MESSAGE,
          payload: '',
        }),
      NOTIF_DISPLAY_DURATION,
    );
  };

  updateSuccessMessage = (payload: any, dispatch: Dispatch) => {
    clearTimeout(this.successTimeoutId);
    dispatch({
      type: UPDATE_SUCCESS_MESSAGE,
      payload,
    });
    this.successTimeoutId = setTimeout(
      () =>
        dispatch({
          type: UPDATE_SUCCESS_MESSAGE,
          payload: '',
        }),
      NOTIF_DISPLAY_DURATION,
    );
  };
}

const notifActionBuilder = new NotifActionBuilder();

export default notifActionBuilder;
