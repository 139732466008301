import {
  GET_ADMIN_APPS_ERROR,
  GET_ADMIN_APPS_SUCCESS,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_ERROR,
  GET_APPS_SUCCESS,
  GET_APPS_ERROR,
  GET_ADMIN_APPLICATION_SUCCESS,
  GET_ADMIN_APPLICATION_ERROR,
  PATCH_APPLICATION_SUCCESS,
  PATCH_APPLICATION_ERROR,
} from './actionTypes';

export const receivedAdminApps = (payload: any) => ({
  type: GET_ADMIN_APPS_SUCCESS,
  payload,
});

export const adminAppsError = (payload: any) => ({
  type: GET_ADMIN_APPS_ERROR,
  payload,
});

export const receivedAdminApplication = (payload: any) => ({
  type: GET_ADMIN_APPLICATION_SUCCESS,
  payload,
});

export const adminApplicationError = (payload: any) => ({
  type: GET_ADMIN_APPLICATION_ERROR,
  payload,
});

export const receivedApps = (payload: any) => ({
  type: GET_APPS_SUCCESS,
  payload,
});

export const appsError = (payload: any) => ({
  type: GET_APPS_ERROR,
  payload,
});

export const patchedApplication = (payload: any) => ({
  type: PATCH_APPLICATION_SUCCESS,
  payload,
});

export const patchApplicationError = (payload: any) => ({
  type: PATCH_APPLICATION_ERROR,
  payload,
});

export const addedApplication = (payload: any) => ({
  type: ADD_APPLICATION_SUCCESS,
  payload,
});

export const addApplicationError = (payload: any) => ({
  type: ADD_APPLICATION_ERROR,
  payload,
});
