import { formatName } from '.';
import { NODE_ENV, PORT, SUBDOMAIN_APP, APP_HOST, WALDO_URL, ITEMS_PER_PAGE } from './constants';

export class RouteFormatter {
  static notFoundRoute = () => '/errors/404';

  static applicationRoute = (
    appName: string,
    applicationId: string,
    semVer = 'latest',
    versionId = 'latest',
    pageNumber = 1,
  ) => `/app/${formatName(appName)}/${applicationId}/${semVer}/${versionId}/${pageNumber}`;

  static flowRoute = (appName: string, applicationId: string, appFlowName: string, appFlowId: string) =>
    `/flow/${formatName(appName)}/${applicationId}/${formatName(appFlowName)}/${appFlowId}`;

  static versionFlowRoute = (
    appName: string,
    applicationId: string,
    appFlowName: string,
    appFlowId: string,
    versionFlowId: string,
  ) => `/flow/${formatName(appName)}/${applicationId}/${formatName(appFlowName)}/${appFlowId}/${versionFlowId}`;

  static embedRoute = (
    appName: string,
    applicationId: string,
    appFlowName: string,
    appFlowId: string,
    versionFlowId: string,
  ) => `/embed/${formatName(appName)}/${applicationId}/${formatName(appFlowName)}/${appFlowId}/${versionFlowId}`;

  static appFlowNameRoute = (appFlowName: string, pageNumber = 1) =>
    `/appFlowName/${encodeURI(appFlowName)}/${pageNumber}`;

  static adminApplicationRoute = (applicationId: string, versionId: string) => `/apps/${applicationId}/${versionId}`;

  static waldoApplicationLink = (applicationId: string) => `${WALDO_URL}applications/${applicationId}`;

  static waldoAppFlowLink = (applicationId: string, appFlowId: string) =>
    `${WALDO_URL}applications/${applicationId}/flows/${appFlowId}`;
}

export class RouteParser {
  static homeRoute = (path: string) => {
    const splitPath = path.split('/');
    return {
      pageNumber: splitPath[1] || '1',
    };
  };

  static applicationRoute = (path: string) => {
    const splitPath = path.split('/');
    return {
      appName: splitPath[2],
      applicationId: splitPath[3],
      semVer: splitPath[4],
      versionId: splitPath[5],
      pageNumber: splitPath[6],
    };
  };

  static flowRoute = (path: string) => {
    const splitPath = path.split('/');
    return {
      appName: splitPath[2],
      applicationId: splitPath[3],
      appFlowName: splitPath[4],
      appFlowId: splitPath[5],
    };
  };

  static versionFlowRoute = (path: string) => {
    const splitPath = path.split('/');
    return {
      appName: splitPath[2],
      applicationId: splitPath[3],
      appFlowName: splitPath[4],
      appFlowId: splitPath[5],
      versionFlowId: splitPath[6],
    };
  };

  static embedFlowRoute = (path: string) => {
    const splitPath = path.split('/');
    return {
      appName: splitPath[2],
      applicationId: splitPath[3],
      appFlowName: splitPath[4],
      appFlowId: splitPath[5],
      versionFlowId: splitPath[6],
    };
  };

  static appFlowRoute = (path: string) => {
    const splitPath = path.split('/');
    return {
      appFlowName: splitPath[2],
      pageNumber: splitPath[3],
    };
  };

  static adminApplicationRoute = (path: string) => {
    const splitPath = path.split('/');
    return {
      applicationId: splitPath[2],
      versionId: splitPath[3],
    };
  };
}

export const getAppHostname = () => {
  const isDevelopment = NODE_ENV === 'development';
  const protocol = isDevelopment ? 'http' : 'https';
  const port = isDevelopment ? `:${PORT}` : '';
  return `${protocol}://${SUBDOMAIN_APP}.${APP_HOST}${port}`;
};

export const pageNumberToOffset = (pageNumber: string) => (parseInt(pageNumber, 10) - 1) * ITEMS_PER_PAGE;

export const getRequestLimit = () => ITEMS_PER_PAGE + 1;

export const getPageNumberParams = (pageNumber: string) => ({
  offset: pageNumberToOffset(pageNumber),
  limit: getRequestLimit(),
});
