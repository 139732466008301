import React, { PureComponent } from 'react';
import { Flex, Text, Box } from 'rebass';
import { Label, Input } from '@rebass/forms';
import { History } from 'history';
import { Modal } from 'antd';
import { match } from 'react-router-dom';

import { AuthPage } from '../../types/common';
import theme from '../../theme';
import { AuthApi } from '../../api/api';
import CustomButton from '../../components/common/CustomButton';
import { getToken } from '../../utils/token';

type Params = {
  code: string;
};

type Props = {
  getUser: (authToken: string) => void;
  history: History;
  navigateTo: (page?: AuthPage) => void;
  match: match<Params>;
};

type OwnState = {
  password: string;
  error: string;
};

class ResetContainer extends PureComponent<Props, OwnState> {
  state = {
    password: '',
    error: '',
  };

  formRef?: HTMLFormElement;

  setFormRef = (ref: HTMLFormElement) => {
    if (ref) {
      this.formRef = ref;
    }
  };

  onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value });

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!this.formRef) return;
    const valid = this.formRef.reportValidity();
    if (valid) {
      await this.onSubmit();
    }
  };

  onFinish = () => this.props.history.push('/');

  onSubmit = async () => {
    this.setState({ error: '' });
    const { password } = this.state;
    const { match, getUser } = this.props;
    const { code } = match.params;
    try {
      await AuthApi.clientUpdatePassword(code, password);
      const authToken = getToken();
      if (authToken) {
        await getUser(authToken);
      }
      this.onFinish();
    } catch (err) {
      this.setState({ error: 'Something went wrong' });
    }
  };

  renderForm = () => {
    const { password, error } = this.state;
    return (
      <Flex width={1} flexDirection="column" as="form" ref={this.setFormRef}>
        <Text mb={3} sx={{ ...theme.styles.p, color: theme.colors.red }}>
          {error}
        </Text>
        <Box width={1} mb={3}>
          <Label mb={2} htmlFor="password" sx={{ fontWeight: 500, fontSize: 2 }}>
            New password*
          </Label>
          <Input
            py={3}
            id="password"
            name="password"
            onChange={this.onPasswordChange}
            placeholder="Enter your new password"
            required
            minLength={6}
            type="password"
            value={password}
            variant="input"
          />
        </Box>
        <CustomButton onClick={this.handleSubmit}>Reset password</CustomButton>
      </Flex>
    );
  };

  renderContent = () => (
    <Flex width={500} px={[3, 5]} py={[4, 5]} flexDirection="column" alignItems="center">
      <Text mb={3} sx={{ ...theme.styles.h1, fontWeight: 'bold' }}>
        Reset password
      </Text>
      {this.renderForm()}
    </Flex>
  );

  render = () => (
    <Modal
      visible
      onCancel={this.onFinish}
      width={500}
      bodyStyle={{ padding: 0, display: 'flex', justifyContent: 'center' }}
      footer={null}
    >
      {this.renderContent()}
    </Modal>
  );
}

export default ResetContainer;
