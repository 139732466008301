import React from 'react';
import { Box, Text, Flex, Button, Link as RebassLink } from 'rebass';

import theme from '../../theme';
import Logo from './Logo';
import { WALDO_SESSIONS_URL } from '../../utils/constants';
import { Link as RouterLink } from 'react-router-dom';
import { RouteFormatter } from '../../utils/routes';

const MAX_APPFLOW_LENGTH = 10;

const renderColumnLink = (names: string[]) =>
  names.length > 0 ? (
    <Flex flexDirection="column" mt={[0, '45px']} mb={[4, 0]} width={100}>
      {names.map((name) => (
        <RouterLink key={name} to={RouteFormatter.appFlowNameRoute(name)}>
          <Text mb={1} color={theme.colors.pearl} sx={{ ...theme.variants.link, color: theme.colors.pearl }}>
            {name.length > MAX_APPFLOW_LENGTH ? `${name.substring(0, MAX_APPFLOW_LENGTH)}...` : name}
          </Text>
        </RouterLink>
      ))}
    </Flex>
  ) : null;

type Props = {
  appFlowNames: string[];
};

const Footer = ({ appFlowNames }: Props) => (
  <Box width={1} pt={5} bg={theme.colors.whitePearl}>
    <Flex width={[95 / 100, 8 / 10]} pb={[6, 5]} m="auto" flexDirection="column">
      <Flex
        width={1}
        pb={3}
        sx={{ borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: theme.colors.pearl }}
        flexDirection={['column', 'row', 'row']}
      >
        <Flex width={1} mb={5} flexDirection="column" alignItems="flex-start">
          <Logo />
          <Text as="p" my={3} pr={3} color={theme.colors.pearl} fontSize={1}>
            UX-Archive is powered by Waldo, the missing collaborative tool for mobile teams.
          </Text>
          <RebassLink href={WALDO_SESSIONS_URL} target="_blank">
            <Button mb={3}>Start a Session</Button>
          </RebassLink>
        </Flex>
        <Flex width={1} minWidth={['none', 350]}>
          <Flex width={1} flexDirection={['column', 'row']}>
            <Flex width={1} justifyContent={['space-around', 'space-evenly']}>
              {renderColumnLink(appFlowNames.slice(0, 4))}
              {renderColumnLink(appFlowNames.slice(4, 8))}
            </Flex>
            <Flex width={1} justifyContent={['space-around', 'space-evenly']}>
              {renderColumnLink(appFlowNames.slice(8, 12))}
              {renderColumnLink(appFlowNames.slice(12, 16))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems="center" flexDirection={['column', 'row']}>
        <Text as="p" my={3} pb={2} color={theme.colors.pearl} fontSize={1}>
          © Waldo 2023, All Rights Reserved -
        </Text>
        <RebassLink
          pl={1}
          pb={2}
          href="https://www.waldo.io/terms/privacy/"
          target="_blank"
          sx={{
            color: theme.colors.primary,
            fontWeight: 500,
            ':hover': { color: theme.colors.primary, textDecoration: 'underline' },
          }}
        >
          Privacy Policy
        </RebassLink>
        <Text as="p" my={3} color={theme.colors.pearl} fontSize={1} sx={{ display: ['none', 'block'] }}>
          &nbsp;-
        </Text>
        <RebassLink
          pl={1}
          pb={2}
          href="mailto:contact@uxarchive.com"
          sx={{
            color: theme.colors.primary,
            fontWeight: 500,
            ':hover': { color: theme.colors.primary, textDecoration: 'underline' },
          }}
        >
          Contact us
        </RebassLink>
      </Flex>
    </Flex>
  </Box>
);

export default Footer;
