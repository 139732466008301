import React, { PureComponent, Fragment } from 'react';
import { Box, Text, Button } from 'rebass';

import theme from '../../theme';

type Props = {
  text: string;
};

type OwnState = {
  expanded: boolean;
};

class Description extends PureComponent<Props, OwnState> {
  state = {
    expanded: false,
  };

  toggleExpand = () => this.setState({ expanded: !this.state.expanded });

  render = () => {
    const { text } = this.props;
    return (
      <Box width={1}>
        {this.state.expanded ? (
          <Fragment>
            <Text sx={{ ...theme.styles.p, whiteSpace: 'pre-wrap' }}>{text}</Text>
            <Button p={0} onClick={this.toggleExpand} variant="text">
              less
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Text sx={{ ...theme.styles.p, display: 'inline', whiteSpace: 'pre-wrap' }}>
              {`${text.substring(0, 220)}...`}
            </Text>
            <Button p={1} onClick={this.toggleExpand} variant="text">
              more
            </Button>
          </Fragment>
        )}
      </Box>
    );
  };
}

export default Description;
