import React, { PureComponent } from 'react';
import Loader from './Loader';
import { Button, Flex } from 'rebass';

type Props = any;

type OwnState = {
  loading: boolean;
};

class CustomButton extends PureComponent<Props, OwnState> {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setLoading = (loading: boolean) => {
    if (this.mounted) {
      this.setState({ loading });
    }
  };

  handleClick = async (e: any) => {
    const { onClick, href } = this.props;
    if (href) {
      window.location.href = href;
    } else if (onClick) {
      this.setState({ loading: true });
      try {
        await onClick(e);
      } finally {
        this.setLoading(false);
      }
    }
  };

  mounted = false;

  render = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onClick, ...props } = this.props;
    return (
      <Button onClick={this.handleClick} {...props}>
        <Flex width={1} justifyContent="center" alignItems="center">
          {this.state.loading && <Loader mx={2} color="white" size={25} width={25} mt="-5px" />}
          {this.props.children}
        </Flex>
      </Button>
    );
  };
}

export default CustomButton;
