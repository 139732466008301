import React from 'react';
import { Flex } from 'rebass';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

type Props = {
  height?: string;
  mx?: number;
  width?: number;
  children: any;
  values: any[];
  onClick: (values: any) => void;
  styleProps?: any;
};

const CustomDropdown = (props: Props) => {
  const { width = 1, height = '100%', mx = 0, styleProps = {} } = props;
  const menu = (
    <Menu style={{ width: 200 }}>
      {props.values.map((value, index) => {
        return (
          <Menu.Item key={index} onClick={() => props.onClick(value)}>
            {value}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <Flex width={width} height={height} mx={mx} alignItems="center" {...styleProps}>
      <Dropdown.Button overlay={menu} icon={<DownOutlined />}>
        {props.children}
      </Dropdown.Button>
    </Flex>
  );
};

export default CustomDropdown;
