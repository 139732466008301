import React from 'react';
import { Box, Text, Flex } from 'rebass';
import Select, { components } from 'react-select';

import { App } from '../../types/common';
import * as rebassTheme from '../../theme';

const customStyles = {
  option: (provided: any) => ({
    ...provided,
    fontWeight: 500,
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: 8,
    borderWidth: 1,
    height: 42,
    cursor: 'text',
  }),
  container: (provided: any) => ({
    ...provided,
    width: 160,
    zIndex: 10,
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: 5,
    width: 220,
    transform: 'translate(-30px)',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: rebassTheme.theme.colors.smoke,
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 0,
  }),
};

type Props = {
  applications: App[];
  onSelect: (application: App) => void;
};

const { Option } = components;
const IconOption = (props: any) => (
  <Option {...props}>
    <img
      src={`${props.data.icon}?w=48`}
      style={{ width: 24, marginRight: 10, borderRadius: 4 }}
      alt={props.data.label}
    />
    {props.data.label}
  </Option>
);

const SearchBar = (props: Props) => {
  const handleSelect = ({ value }: any) => {
    const { applications, onSelect } = props;
    const application = applications.find(({ name }) => name === value);
    if (application) {
      onSelect(application);
    }
  };

  const ValueContainer = ({ children, ...props }: any) => {
    return (
      components.ValueContainer && (
        <components.ValueContainer {...props}>
          <Flex sx={{ overflow: 'hidden' }}>
            {!!children && (
              <Text
                as="span"
                className="icon-search-icon"
                sx={{ fontSize: 3, color: rebassTheme.theme.colors.smoke, fontWeight: 'normal' }}
              />
            )}
            <Text
              maxWidth={120}
              pl={1}
              sx={{ ...rebassTheme.theme.styles.h4, fontWeight: 'normal', lineHeight: '17px' }}
            >
              {children}
            </Text>
          </Flex>
        </components.ValueContainer>
      )
    );
  };

  const options = props.applications.map((application) => ({
    value: application.name,
    label: application.name,
    icon: application.latestVersion.iconUrl,
  }));

  return (
    <Box width={['auto', 1]} ml={[2, 0]}>
      <Select
        options={options}
        styles={customStyles}
        instanceId="searchbar"
        controlShouldRenderValue={false}
        placeholder="Search for an app"
        onChange={handleSelect}
        components={{ ValueContainer, Option: IconOption }}
        noOptionsMessage={() => 'No result found'}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: rebassTheme.theme.colors.smokeLighten90,
            primary25: rebassTheme.theme.colors.smokeLighten90,
            primary: rebassTheme.theme.colors.primary,
          },
        })}
      />
    </Box>
  );
};

export default SearchBar;
