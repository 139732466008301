import React from 'react';
import { Flex, Text, Image, Box } from 'rebass';

import theme from '../../theme';
import SignupBanner from './SignupBanner';
import mailboxSrc from '../../assets/icons/waldo_check_your_email.png';

const ConfirmEmail = () => (
  <Flex>
    <Box width={500} sx={{ display: ['none', 'none', 'block'] }}>
      <SignupBanner />
    </Box>
    <Flex
      width={500}
      height="100%"
      py={[4, 5]}
      px={[3, 4]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ textAlign: 'center' }}
    >
      <Text width={1} mb={4} sx={{ ...theme.styles.h1, fontWeight: 'bold' }}>
        Check your email
      </Text>
      <Text width={1} sx={{ fontSize: 1 }}>
        Confirm your email address to start using UXArchive. If you do not receive it right away, please check your spam
        folder.
      </Text>
      <Image width={500} src={mailboxSrc} alt="mailbox" />
    </Flex>
  </Flex>
);

export default ConfirmEmail;
