import React, { PureComponent } from 'react';
import { Flex, Text, Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';

import { AuthPage } from '../../types/common';
import theme from '../../theme';
import { AuthApi } from '../../api/api';
import { getToken } from '../../utils/token';
import CustomButton from '../../components/common/CustomButton';

type Props = {
  getUser: (authToken: string) => void;
  navigateTo: (page?: AuthPage) => void;
};

type OwnState = {
  email: string;
  password: string;
  error: string;
};

class LoginContainer extends PureComponent<Props, OwnState> {
  state = {
    email: '',
    password: '',
    error: '',
  };

  formRef?: HTMLFormElement;

  setFormRef = (ref: HTMLFormElement) => {
    if (ref) {
      this.formRef = ref;
    }
  };

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value });

  onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value });

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!this.formRef) return;
    const valid = this.formRef.reportValidity();
    if (valid) {
      await this.onSubmit();
    }
  };

  onSubmit = async () => {
    this.setState({ error: '' });
    const { email, password } = this.state;
    const { getUser, navigateTo } = this.props;
    try {
      await AuthApi.login(email, password);
      navigateTo();
      const authToken = getToken();
      if (authToken) {
        await getUser(authToken);
      }
    } catch (err: any) {
      const { response } = err;
      if (response && response.status === 401) {
        this.setState({ error: 'Invalid email and/or password.' });
      } else {
        this.setState({ error: 'Something went wrong' });
      }
    }
  };

  renderForm = () => {
    const { email, password, error } = this.state;
    return (
      <Flex width={1} flexDirection="column" as="form" ref={this.setFormRef}>
        <Text mb={3} sx={{ ...theme.styles.p, color: theme.colors.red }}>
          {error}
        </Text>
        <Box width={1} mb={'24px'}>
          <Label mb={2} htmlFor="email" sx={{ fontWeight: 500, fontSize: 2 }}>
            Email address*
          </Label>
          <Input
            py={3}
            id="email"
            name="email"
            onChange={this.onEmailChange}
            placeholder="Enter email address"
            required
            type="email"
            value={email}
            variant="input"
          />
        </Box>
        <Box width={1} mb={0}>
          <Label mb={2} htmlFor="password" sx={{ fontWeight: 500, fontSize: 2 }}>
            Password*
          </Label>
          <Input
            py={3}
            id="password"
            name="password"
            onChange={this.onPasswordChange}
            placeholder="Enter password"
            required
            type="password"
            value={password}
            variant="input"
          />
        </Box>
        <Button
          p={[0, 0]}
          mb={2}
          onClick={() => this.props.navigateTo('forgot-password')}
          variant="text"
          sx={{ width: 'fit-content', alignSelf: 'center', fontSize: 1 }}
        >
          Forgot password?
        </Button>
        <CustomButton onClick={this.handleSubmit}>Sign In</CustomButton>
      </Flex>
    );
  };

  renderLink = () => (
    <Flex width={1} justifyContent="flex-start" alignItems="center">
      <Text sx={{ fontSize: 1, fontWeight: 500 }}>{"Don't have an account?"}</Text>
      <Button
        p={[0, 0]}
        ml={2}
        variant="text"
        onClick={() => this.props.navigateTo('signup')}
        sx={{ fontSize: 1, fontWeight: 500 }}
      >
        Sign Up
      </Button>
    </Flex>
  );

  render = () => (
    <Flex width={500} px={[3, 5]} py={[4, 5]} flexDirection="column" alignItems="center">
      <Text mb={3} sx={{ ...theme.styles.h1, fontWeight: 'bold' }}>
        Sign In
      </Text>
      {this.renderForm()}
      {this.renderLink()}
    </Flex>
  );
}

export default LoginContainer;
