import { serverConfig } from '../../server/config';

export const isBrowser = () => typeof window !== 'undefined';

export const isServer = () => typeof process !== 'undefined';

export const getConfig = (name: string) => {
  if (isBrowser() && window.config) {
    return window.config[name];
  } else if (isServer()) {
    return serverConfig[name];
  }
  return null;
};

export const getBooleanConfig = (name: string, defaultValue = false) => {
  const configValue = getConfig(name);
  if (configValue === null || configValue === undefined) {
    return defaultValue;
  }
  if (typeof configValue === 'string') {
    return configValue.toLowerCase() === 'true';
  }

  return Boolean(configValue);
};

export const redirectTo = (url: string) => (window.location.href = url);

export const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const formatName = (appName: string) =>
  appName
    .split(' ')
    .join('-')
    .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
    .toLowerCase();

export const formatAppStoreUrl = (name: string, appStoreId: string) =>
  `https://apps.apple.com/us/app/${formatName(name)}/id${appStoreId}`;

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const formatDate = (textDate: string) => {
  const date = new Date(textDate);
  // Here we could have used...
  // const month = date.toLocaleString('default', { month: 'long' });
  // But that renders differently on the server, so falling back to lovely MONTH_NAMES
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  return `${month} ${year}`;
};
