import React from 'react';
import { Flex } from 'rebass';

import { Flow, Interaction } from '../../types/common';
import Device from './Device';
import { Link as RouterLink } from 'react-router-dom';
import { DEVICE_SCREEN_WIDTH } from '../../utils/constants';

type AdminFlowProps = {
  tagInputs: string[];
  onChange: (index: number, value: string) => void;
};

type Props = {
  flow: Flow;
  link?: string;
  adminProps?: AdminFlowProps;
  responsive?: boolean;
  mode?: 'flow' | 'modal';
};

const FlowComponent = (props: Props) => {
  const { flow, link, adminProps, responsive, mode } = props;
  const { width: screenWidth, height: screenHeight } = flow.device.screen;

  const widthSettings = responsive ? ['20vh', '25vh'] : DEVICE_SCREEN_WIDTH;
  let cursor = 'pointer';
  if (mode === 'flow') {
    cursor = 'zoom-in';
  } else if (mode === 'modal') {
    cursor = 'default';
  }

  const renderDevice = (interaction: Interaction, index: number) => (
    <Flex key={`${interaction.screenshot}-${index}`} width={widthSettings} minWidth={widthSettings} mr={3}>
      <Device
        interaction={interaction}
        index={index}
        adminProps={adminProps ? { ...adminProps, tagInput: adminProps.tagInputs[index] } : undefined}
        responsive={responsive}
        screenWidth={screenWidth}
        screenHeight={screenHeight}
      />
    </Flex>
  );

  const renderContent = () => (
    <Flex width={1} p={1} sx={{ overflow: 'auto' }}>
      {flow.interactions.map(renderDevice)}
    </Flex>
  );

  return link ? (
    <RouterLink to={link} style={{ textDecoration: 'none', cursor }}>
      {renderContent()}
    </RouterLink>
  ) : (
    renderContent()
  );
};

export default FlowComponent;
