import { Dispatch } from 'redux';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Box, Text, Flex, Image, Link as RebassLink } from 'rebass';
import { Link, match } from 'react-router-dom';
import { History } from 'history';
import * as _ from 'lodash';

import ActionBuilder from '../../store/actions';
import { AppState, Flow, AppVersion, Action, InitialActionParams, AuthPage, User } from '../../types/common';
import ActionBoxContainer from './ActionBoxContainer';
import PoweredBy from '../../components/client/PoweredBy';
import Share from '../../components/client/Share';
import { formatAppStoreUrl } from '../../utils';
import FlowComponent from '../../components/common/FlowComponent';
import theme from '../../theme';
import extLinkSrc from '../../assets/icons/external-link.svg';
import Version from '../../components/client/Version';
import { LEFT_BANNER_WIDTH, ITEMS_PER_PAGE } from '../../utils/constants';
import { RouteFormatter, RouteParser, getPageNumberParams } from '../../utils/routes';
import Badge from '../../components/client/Badge';
import { sortVersions, generateQuery } from '../../utils/flows';
import Description from '../../components/common/Description';
import PageManager from '../../components/client/PageManager';
import Loader from '../../components/common/Loader';
import WaldoBadge from '../../components/client/WaldoBadge';

const FLOW_NAME_MAX_LENGTH = 15;

const formatFlowName = (name: string) =>
  name.length > FLOW_NAME_MAX_LENGTH ? `${name.substring(0, FLOW_NAME_MAX_LENGTH)}...` : name;

type Params = {
  appName: string;
  applicationId: string;
  semVer: string;
  versionId: string;
  pageNumber?: string;
};

type InitProps = {
  location: any;
};

type Props = InitProps & {
  appVersions: AppVersion[];
  dispatch: Dispatch;
  embed: (link: string) => void;
  flows?: Flow[];
  history: History;
  match: match<Params>;
  navigateTo: (page?: AuthPage) => void;
  onDownload: (flow: Flow) => void;
  updateErrorMessage: (message: string) => void;
  updateSuccessMessage: (message: string) => void;
  user: User | null;
};

class ApplicationContainer extends PureComponent<Props> {
  static initialAction = async ({ url }: InitialActionParams) => {
    const defaultResult = Promise.resolve([] as Action[]);
    if (!url) {
      return defaultResult;
    }
    const { applicationId, versionId: rawVersionId, pageNumber } = RouteParser.applicationRoute(url);
    return Promise.all([
      ActionBuilder.getFlows({
        applicationId,
        versionId: rawVersionId === 'latest' ? undefined : rawVersionId,
        ...getPageNumberParams(pageNumber),
      }),
      ActionBuilder.getAppVersions(applicationId),
    ]);
  };

  componentDidMount = () => {
    this.checkForNotFound();
    this.fetchResources();
  };

  componentDidUpdate = (prevProps: Props) => {
    this.checkForNotFound();
    if (prevProps.location !== this.props.location) {
      this.fetchResources();
    }
  };

  checkForNotFound = () => {
    const { appVersions, flows, history } = this.props;
    if (appVersions === null || _.isEqual(flows, [])) {
      history.push(RouteFormatter.notFoundRoute());
    }
  };

  fetchResources = async () => {
    const {
      dispatch,
      location: { pathname },
    } = this.props;
    const actions = await ApplicationContainer.initialAction({ url: pathname });
    actions.map(dispatch);
  };

  getPageNumber = () => parseInt(this.props.match.params.pageNumber || '1', 10);

  getFlowsCount = () => {
    const { flows } = this.props;
    if (!flows) {
      return 0;
    }
    return (this.getPageNumber() - 1) * ITEMS_PER_PAGE + flows.length;
  };

  onPageNumberChange = (pageNumber: number) => {
    const { appName, applicationId, semVer, versionId } = this.props.match.params;
    this.props.history.push(RouteFormatter.applicationRoute(appName, applicationId, semVer, versionId, pageNumber));
  };

  renderFlow = (flow: Flow) => {
    const {
      application: { name: appName, id: applicationId },
      appFlow: { id: appFlowId, name: appFlowName, displayName },
    } = flow;
    const { embed, onDownload, updateSuccessMessage } = this.props;
    return (
      <Flex width={1} key={flow.id} mb={5} flexDirection={['column', 'column', 'row']}>
        <Flex mb={3} flexDirection="column" minWidth={[1, 1, LEFT_BANNER_WIDTH]}>
          <Link to={RouteFormatter.appFlowNameRoute(appFlowName)} style={{ textDecoration: 'none' }}>
            <Text mb={2} sx={{ ...theme.styles.h2 }}>
              {formatFlowName(displayName)}
            </Text>
          </Link>
          <Box mt={1} mb={3}>
            <WaldoBadge />
          </Box>
          <Share flow={flow} updateSuccessMessage={updateSuccessMessage} onDownload={onDownload} embed={embed} />
        </Flex>
        <Box width="100%">
          <FlowComponent flow={flow} link={RouteFormatter.flowRoute(appName, applicationId, appFlowName, appFlowId)} />
        </Box>
      </Flex>
    );
  };

  renderFlows = (flows: Flow[]) => {
    const pageFlows = flows.slice(0, ITEMS_PER_PAGE);
    return pageFlows.length > 0 ? (
      <Box width={1} mb={5}>
        {pageFlows.map(this.renderFlow)}
      </Box>
    ) : (
      <Loader height="50vh" />
    );
  };

  renderAppInfo = () => {
    const {
      appVersions,
      match: {
        params: { versionId },
      },
    } = this.props;
    if (!appVersions || appVersions.length === 0) {
      return <Loader height="70vh" />;
    }
    const appVersion = appVersions.find(({ id }) => id === versionId) || appVersions[appVersions.length - 1];
    const {
      iconUrl,
      meta: { categories, description, name, identity, appStoreId },
    } = appVersion;
    return (
      <Flex width={1}>
        <Image
          width={[50, 110]}
          variant="iconShadow"
          minWidth={[50, 110]}
          height={[50, 110]}
          src={iconUrl}
          sx={{ borderRadius: 15 }}
          alt="icon"
        />
        <Flex width={900} pl="24px" flexDirection="column">
          <Flex width={1} alignItems={['flex-start', 'center']} flexDirection={['column', 'row']}>
            <Text as="h1" pb={1} sx={{ ...theme.styles.h2 }}>
              {name}
            </Text>
            <Badge text={categories[0]} ml={[0, 3]} mb={[2, 0]} />
          </Flex>
          <Box width={1}>
            <Description text={description} />
          </Box>
          <RebassLink
            mt={1}
            href={formatAppStoreUrl(name, appStoreId)}
            target="_blank"
            sx={{
              ...theme.styles.h4,
              color: theme.colors.primary,
              width: 'fit-content',
              ':hover': {
                color: theme.colors.primary,
                textDecoration: 'underline',
              },
            }}
          >
            {`© ${identity}`}
            <Image ml={1} width={10} height={10} src={extLinkSrc} alt="ext-link" />
          </RebassLink>
        </Flex>
      </Flex>
    );
  };

  // semvers are sorted by version - so first one is the latest
  renderVersionComponent = (appVersion: AppVersion, latest: boolean) => {
    const {
      version: semVer,
      id,
      meta: { createdAt, name },
      applicationId,
    } = appVersion;
    const {
      match: {
        params: { versionId: routeVersionId },
      },
      user,
      navigateTo,
      history,
    } = this.props;
    const isSelected = (latest && routeVersionId === 'latest') || id === routeVersionId;
    const onClick = () => {
      if (user) {
        history.push(RouteFormatter.applicationRoute(name, applicationId, semVer, id));
      } else {
        navigateTo('signup');
      }
    };
    return (
      <Version
        key={semVer}
        semVer={semVer}
        onClick={onClick}
        createdAt={createdAt}
        latest={latest}
        selected={isSelected}
      />
    );
  };

  renderVersions = () => {
    const { appVersions } = this.props;
    return (
      <Flex width={1} my={4} alignItems="center">
        <Text pb={2} width={LEFT_BANNER_WIDTH} sx={{ ...theme.styles.h3 }}>
          Versions
        </Text>
        <Flex width={1} justifyContent="flex-start" alignItems="center" sx={{ overflow: 'auto' }}>
          {sortVersions(appVersions).map((item, index) =>
            this.renderVersionComponent(item as AppVersion, index === appVersions.length - 1),
          )}
        </Flex>
      </Flex>
    );
  };

  renderActionBox = () => (
    <Box pl={[0, 0, LEFT_BANNER_WIDTH]}>
      <ActionBoxContainer />
      <Box width={1} mt={80} mb={4}>
        <PoweredBy />
      </Box>
    </Box>
  );

  renderPageManager = () => (
    <Box width={1} mb={5} pl={[0, 0, LEFT_BANNER_WIDTH]}>
      <PageManager
        pageNumber={this.getPageNumber()}
        onPageNumberChange={this.onPageNumberChange}
        itemsLength={this.getFlowsCount()}
      />
    </Box>
  );

  render = () => {
    const { flows } = this.props;
    return (
      <Box width={[95 / 100, 8 / 10]} m="auto" pt={6}>
        {this.renderAppInfo()}
        {this.renderVersions()}
        {this.renderFlows(flows || [])}
        {this.renderPageManager()}
        {this.renderActionBox()}
      </Box>
    );
  };
}

const mapStateToProps = (state: AppState, props: InitProps) => {
  const {
    location: { pathname },
  } = props;
  // Here we don't extract applicationId and versionId from params because this also runs server side
  const { applicationId, versionId: rawVersionId, pageNumber } = RouteParser.applicationRoute(pathname);
  const request = generateQuery({
    applicationId,
    versionId: rawVersionId === 'latest' ? undefined : rawVersionId,
    ...getPageNumberParams(pageNumber),
  });
  let appVersions: AppVersion[] = [];
  if (applicationId && typeof applicationId === 'string') {
    appVersions = state.appVersions[applicationId];
  }
  return {
    appVersions,
    flows: state.flowRequests[request],
  };
};

export default connect(mapStateToProps)(ApplicationContainer);
