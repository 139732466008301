import React from 'react';
import { Flex, Text, Button, Image } from 'rebass';
import { History } from 'history';

import NotFoundSrc from '../../assets/icons/sad-llama.png';

type Props = {
  history: History;
};

const NotFound404 = ({ history }: Props) => {
  const onClick = () => history.push('/');

  return (
    <Flex width={1} height="max-content" justifyContent="center" alignItems="center" flexDirection="column" pt={6}>
      <Image width="40vh" src={NotFoundSrc} alt="404" />
      <Text mb={3} as="h1">
        Oops
      </Text>
      <Text mb={4} as="h3">
        We could not find the page you are looking for...
      </Text>
      <Button mb={3} onClick={onClick}>
        Return to Home
      </Button>
    </Flex>
  );
};

export default NotFound404;
