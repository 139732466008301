import React from 'react';
import { Box, Flex, Text, Link as RebassLink } from 'rebass';

import theme from '../../theme';
import { WALDO_LANDING_URL } from '../../utils/constants';

const ThunderIcon = () => (
  <Box
    as="span"
    className="icon-bolt-icon"
    pb={1}
    px={1}
    sx={{
      fontSize: 22,
      ':before': {
        alignSelf: 'center',
        color: theme.colors.yellow,
      },
    }}
  />
);

const PoweredBy = () => (
  <Flex width={1} alignItems="center" justifyContent="center">
    <ThunderIcon />
    <Text sx={{ ...theme.styles.h4 }}>All flows extracted by</Text>
    <RebassLink
      href={WALDO_LANDING_URL}
      pl={1}
      target="_blank"
      sx={{
        ...theme.styles.h4,
        textDecoration: 'none',
        color: theme.colors.primary,
        cursor: 'pointer',
        ':hover': {
          color: theme.colors.primary,
          textDecoration: 'underline',
        },
      }}
    >
      waldo.com
    </RebassLink>
    <ThunderIcon />
  </Flex>
);

export default PoweredBy;
