import React, { PureComponent } from 'react';
import { Flex, Text, Box, Link as RebassLink, Button } from 'rebass';
import { Label, Input, Checkbox } from '@rebass/forms';

import { AuthPage } from '../../types/common';
import theme from '../../theme';
import SignupBanner from '../../components/client/SignupBanner';
import { AuthApi } from '../../api/api';
import CustomButton from '../../components/common/CustomButton';

type Props = {
  navigateTo: (page?: AuthPage) => void;
};

type OwnState = {
  email: string;
  password: string;
  checked: boolean;
  error: string;
};

class SignupContainer extends PureComponent<Props, OwnState> {
  state = {
    email: '',
    password: '',
    checked: false,
    error: '',
  };

  formRef?: HTMLFormElement;

  setFormRef = (ref: HTMLFormElement) => {
    if (ref) {
      this.formRef = ref;
    }
  };

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value });

  onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value });

  onCheckboxChange = () => this.setState({ checked: !this.state.checked });

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!this.formRef) return;
    const valid = this.formRef.reportValidity();
    if (valid) {
      await this.onSubmit();
    }
  };

  onSubmit = async () => {
    this.setState({ error: '' });
    const { email, password } = this.state;
    const { navigateTo } = this.props;
    try {
      await AuthApi.createUser({ email, password });
      navigateTo('confirm');
    } catch (err: any) {
      const { response } = err;
      if (
        response &&
        response.data &&
        response.data.status === 400 &&
        response.data.details &&
        response.data.details &&
        response.data.details[0] &&
        response.data.details[0].message
      ) {
        const { message } = response.data.details[0];
        if (message === 'code_email_already_used') {
          this.setState({ error: 'This email is already taken.' });
          return;
        } else if (message === 'code_invalid_password_pattern') {
          this.setState({ error: 'Invalid password pattern.' });
          return;
        } else if (message === 'code_forbidden_email_domain') {
          this.setState({ error: "Sorry but we don't accept this email domain." });
          return;
        }
      }
      this.setState({ error: 'Something went wrong' });
    }
  };

  renderForm = () => {
    const { email, password, checked, error } = this.state;
    return (
      <Flex width={1} flexDirection="column" as="form" ref={this.setFormRef}>
        <Text mb={3} sx={{ ...theme.styles.p, color: theme.colors.red }}>
          {error}
        </Text>
        <Box width={1} mb={'24px'}>
          <Label mb={2} htmlFor="email" sx={{ fontWeight: 500, fontSize: 2 }}>
            Email address*
          </Label>
          <Input
            py={3}
            id="email"
            name="email"
            onChange={this.onEmailChange}
            placeholder="Enter email address"
            required
            type="email"
            value={email}
            variant="input"
          />
        </Box>
        <Box width={1} mb={'24px'}>
          <Label mb={2} htmlFor="password" sx={{ fontWeight: 500, fontSize: 2 }}>
            Password*
          </Label>
          <Input
            py={3}
            id="password"
            name="password"
            onChange={this.onPasswordChange}
            minLength={6}
            placeholder="Enter password"
            required
            type="password"
            value={password}
            variant="input"
          />
        </Box>
        <Flex alignItems="center" mb={4}>
          <Label>
            <Checkbox
              id="terms"
              name="terms"
              value={checked}
              onChange={this.onCheckboxChange}
              required
              sx={{
                ':hover': { cursor: 'pointer' },
                'input:focus ~ &': {
                  bg: theme.colors.smokeLighten90,
                },
              }}
            />
            <RebassLink
              href="https://www.waldo.io/terms/terms-of-use/"
              target="_blank"
              sx={{
                fontSize: 1,
                pt: 1,
                fontWeight: 500,
                color: theme.colors.midnight,
                transitionDuration: '.3s',
                ':hover': { textDecoration: 'underline', color: theme.colors.primary },
              }}
            >
              {'Agree to terms & conditions'}
            </RebassLink>
          </Label>
        </Flex>
        <CustomButton onClick={this.handleSubmit}>Sign up</CustomButton>
      </Flex>
    );
  };

  renderLink = () => (
    <Flex width={1} justifyContent="center" alignItems="center">
      <Text sx={{ fontSize: 1, fontWeight: 500 }}>Already have an account?</Text>
      <Button
        p={[0, 0]}
        ml={2}
        variant="text"
        onClick={() => this.props.navigateTo('login')}
        sx={{ fontSize: 1, fontWeight: 500 }}
      >
        Sign in
      </Button>
    </Flex>
  );

  renderContentRight = () => (
    <Flex
      width={500}
      px={[3, '48px']}
      py={[4, '48px']}
      flexDirection="column"
      alignItems="center"
      sx={{ lineHeight: 'normal' }}
    >
      <Text mb={4} sx={{ ...theme.styles.h1, fontWeight: 'bold' }}>
        Sign Up
      </Text>
      {this.renderForm()}
      {this.renderLink()}
    </Flex>
  );

  render = () => (
    <Flex>
      <Box width={500} sx={{ display: ['none', 'none', 'block'] }}>
        <SignupBanner />
      </Box>
      {this.renderContentRight()}
    </Flex>
  );
}

export default SignupContainer;
