import React from 'react';
import { Flex, Text, Box, Image } from 'rebass';

import theme from '../../theme';
import welcomeLlamaSrc from '../../assets/icons/welcome-waldo-uxarchive.png';
import Logo from './Logo';

const SignupBanner = () => {
  const renderBulletPoint = (logo: string, text: string) => (
    <Flex mb={2} px={0}>
      <Text mr={2} sx={{ ...theme.styles.h2, transform: 'translateY(-5px)' }}>
        {logo}
      </Text>
      <Text sx={{ ...theme.styles.h4, color: 'white' }}>{text}</Text>
    </Flex>
  );

  return (
    <Flex width={1} pl={4} pt={4} pb={5} height={'100%'} flexDirection="column" bg={theme.colors.primary}>
      <Logo white withLink width={170} />
      <Image width={328} ml={4} src={welcomeLlamaSrc} alt="welcome-llama" sx={{ alignSelf: 'left' }} />
      <Box px={1}>{renderBulletPoint('📱', 'Get access to +150 apps & +1000 flows')}</Box>
      <Box px={1}>{renderBulletPoint('💡', 'Save flows for your inspiration')}</Box>
      <Box px={1}>{renderBulletPoint('💾', 'Download +10000 screens from your favorite apps')}</Box>
      <Box px={1}>{renderBulletPoint('💌', 'Stay up to date about the latest UX trends')}</Box>
    </Flex>
  );
};

export default SignupBanner;
