import React from 'react';
import { Flex, Box, Text } from 'rebass';

import { Interaction, ScreenAction } from '../../types/common';
import { Select } from '@rebass/forms';
import theme from '../../theme';
import { DEVICE_SCREEN_WIDTH, SCREEN_TAG_OPTIONS } from '../../utils/constants';

const ACTION_TO_ICON_MAPPER: Record<ScreenAction, any> = {
  BACK: 'icon-back-icon',
  CLICK_OUT: 'icon-tap-icon',
  CLICK: 'icon-tap-icon',
  DEEPLINK: 'icon-upload-icon',
  END: 'icon-bolt-icon',
  ERROR: 'icon-sadphone2-icon',
  INPUT_TEXT: 'icon-keyboard-icon',
  RELAUNCH: 'icon-relaunch-icon',
  SCROLL: 'icon-scroll-icon',
  SHAKE: 'icon-shake-icon',
  SUBMIT_TEXT: 'icon-keyboard-icon',
  ZOOM_IN: 'icon-plus-icon',
  ZOOM_OUT: 'icon-minus-icon',
};

type LineData = {
  x1: string;
  x2: string;
  y1: string;
  y2: string;
};

type AdminDeviceProps = {
  tagInput: string;
  onChange: (index: number, value: string) => void;
};

type Props = {
  index: number;
  interaction: Interaction;
  adminProps?: AdminDeviceProps;
  responsive?: boolean;
  screenWidth: number;
  screenHeight: number;
};

const DeviceComponent = (props: Props) => {
  const isAdmin = !!props.adminProps;
  const {
    index,
    interaction: { screenshot, action, tags, element, originalTouch },
    adminProps: { onChange, tagInput } = {},
    responsive,
    screenWidth,
    screenHeight,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(index, e.target.value);
    }
  };

  const renderTagInput = () =>
    isAdmin ? (
      <Select mt={2} name="device-tag" value={tagInput} onChange={handleChange}>
        <option value="" hidden>
          Select a tag
        </option>
        {SCREEN_TAG_OPTIONS.map((option) => (
          <option key={option}>{option}</option>
        ))}
      </Select>
    ) : null;

  const renderTag = () => (
    <Text
      textAlign="center"
      mt={1}
      sx={{ ...theme.styles.h5, fontSize: [0, 1, 2], fontWeight: 500, whiteSpace: 'nowrap', overflow: 'hidden' }}
    >
      {tags}
    </Text>
  );

  const renderBox = () => {
    if (!element) {
      return null;
    }
    const { boundingBox } = element;
    return (
      <Box
        sx={{
          position: 'absolute',
          top: `${(boundingBox.top / screenHeight) * 100}%`,
          left: `${(boundingBox.left / screenWidth) * 100}%`,
          width: `${(boundingBox.width / screenWidth) * 100}%`,
          height: `${(boundingBox.height / screenHeight) * 100}%`,
          zIndex: 2,
          opacity: 0.5,
          bg: theme.colors.primary,
        }}
      />
    );
  };

  const renderInteraction = () => {
    if (!originalTouch) {
      return null;
    }
    const { toX, toY } = originalTouch;
    return (
      <Box
        sx={{
          position: 'absolute',
          top: `${(toY / screenHeight) * 100}%`,
          left: `${(toX / screenWidth) * 100}%`,
          zIndex: 3,
          width: 30,
          height: 30,
          bg: theme.colors.smokeLighten90,
          opacity: 0.5,
          transform: 'translate(-15px, -15px)',
          borderRadius: '100%',
          boxShadow: '0 0 4px 0 rgba(0,0,0,.4)',
        }}
      />
    );
  };

  const renderLine = (lineData: LineData) => {
    const { x1, x2, y1, y2 } = lineData;

    const gradientId = screenshot;

    return (
      <svg
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 3,
        }}
      >
        <linearGradient id={`gradient_${gradientId}`} gradientUnits="userSpaceOnUse" x1={x1} x2={x2} y1={y1} y2={y2}>
          <stop offset="0%" stopColor="rgba(255, 210, 88, 0)" />
          <stop offset="100%" stopColor={theme.colors.yellow} />
        </linearGradient>

        <line
          strokeWidth="4"
          strokeLinecap="round"
          stroke={`url(#gradient_${gradientId})`}
          x1={x1}
          x2={x2}
          y1={y1}
          y2={y2}
        />
      </svg>
    );
  };

  const renderSwipeLine = () => {
    if (!originalTouch) {
      return null;
    }
    const { toX, toY, fromX, fromY } = originalTouch;
    if (!fromX || !fromY) {
      return null;
    }
    return renderLine({
      x1: `${(fromX / screenWidth) * 100}%`,
      x2: `${(toX / screenWidth) * 100}%`,
      y1: `${(fromY / screenHeight) * 100}%`,
      y2: `${(toY / screenHeight) * 100}%`,
    });
  };

  const swipeLine = renderSwipeLine();
  const box = swipeLine ? null : renderBox();

  return (
    <Flex width={1} flexDirection="column">
      <Box
        width={1}
        sx={{
          borderRadius: [4, 6, 10],
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <img
          width={responsive ? '100%' : DEVICE_SCREEN_WIDTH}
          draggable={false}
          src={`${screenshot}?w=400`}
          alt={`${tags} screenshot `}
          style={{ display: 'block' }}
        />
        {box}
        {renderInteraction()}
        {swipeLine}
      </Box>
      <Box
        as="span"
        className={ACTION_TO_ICON_MAPPER[action]}
        mt={3}
        sx={{
          textAlign: 'center',
          fontSize: [0, 1, 2],
          ':before': {
            alignSelf: 'center',
            color: theme.colors.primary,
          },
        }}
      />
      {isAdmin ? renderTagInput() : renderTag()}
    </Flex>
  );
};

export default DeviceComponent;
