import { getBooleanConfig, getConfig } from './index';

export const AUTH_API_URL = getConfig('RAZZLE_AUTH_API_URL');
export const API_URL = getConfig('RAZZLE_API_URL');
export const WALDO_URL = getConfig('RAZZLE_WALDO_URL');
export const PRINT_STORE_UPDATES = getBooleanConfig('RAZZLE_PRINT_STORE_UPDATES');
export const NODE_ENV = getConfig('NODE_ENV_ORIGINAL') || getConfig('NODE_ENV');
export const SUBDOMAIN_APP = getConfig('RAZZLE_SUBDOMAIN_APP');
export const APP_HOST = getConfig('RAZZLE_HOST');
export const PORT = getConfig('PORT');

export const ADMIN_COOKIE_NAME = 'ADMIN_AUTH_TOKEN';
export const CLIENT_COOKIE_NAME = 'CLIENT_AUTH_TOKEN';

export const DAY = 24 * 60 * 60 * 1000;

// This is hosted on ux-archive so it can pass along its cookies
export const WALDO_LANDING_URL = '/go/waldo';

export const WALDO_SESSIONS_URL = '/go/waldo/sessions';

export const LEFT_BANNER_WIDTH = 250;

export const DEVICE_SCREEN_WIDTH = 200;

export const ITEMS_PER_PAGE = 8;

export const MAX_DISPLAYED_PAGES = 5;

export const REQUEST_STATUS = {
  SUCCESS: 'SUCCESS',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  NOT_FOUND_ERROR: 'NOT_FOUND_ERROR',
};

export const CATEGORY_META = {
  Default: {
    color: 'rgba(15, 188, 73, 1)',
    bg: 'rgba(15, 188, 73, 0.1)',
  },
  Books: {
    color: 'rgba(235, 47, 150, 1)',
    bg: 'rgba(235, 47, 150, 0.1)',
  },
  Business: {
    color: 'rgba(245, 34, 45, 1)',
    bg: 'rgba(245, 34, 45, 0.1)',
  },
  'Developer Tools': {
    color: 'rgba(250, 84, 28, 1)',
    bg: 'rgba(250, 84, 28, 0.1)',
  },
  Education: {
    color: 'rgba(250, 140, 22, 1)',
    bg: 'rgba(250, 140, 22, 0.1)',
  },
  Entertainment: {
    color: 'rgba(250, 173, 20, 1)',
    bg: 'rgba(250, 173, 20, 0.1)',
  },
  Finance: {
    color: 'rgba(160, 217, 17, 1)',
    bg: 'rgba(160, 217, 17, 0.1)',
  },
  'Food & Drink': {
    color: 'rgba(82, 196, 26, 1)',
    bg: 'rgba(82, 196, 26, 0.1)',
  },
  Games: {
    color: 'rgba(19, 194, 194, 1)',
    bg: 'rgba(19, 194, 194, 0.1)',
  },
  'Graphics & Design': {
    color: 'rgba(24, 144, 255, 1)',
    bg: 'rgba(24, 144, 255, 0.1)',
  },
  'Health & Fitness': {
    color: 'rgba(47, 84, 235, 1)',
    bg: 'rgba(47, 84, 235, 0.1)',
  },
  Lifestyle: {
    color: 'rgba(15, 188, 73, 1)',
    bg: 'rgba(15, 188, 73, 0.1)',
  },
  Kids: {
    color: 'rgba(63, 89, 138, 1)',
    bg: 'rgba(63, 89, 138, 0.1)',
  },
  'Magazines & Newspapers': {
    color: 'rgba(90, 113, 132, 1)',
    bg: 'rgba(90, 113, 132, 0.1)',
  },
  Medical: {
    color: 'rgba(254, 113, 113, 1)',
    bg: 'rgba(254, 113, 113, 0.1)',
  },
  Music: {
    color: 'rgba(157, 101, 201, 1)',
    bg: 'rgba(157, 101, 201, 0.1)',
  },
  Navigation: {
    color: 'rgba(42, 61, 102, 1)',
    bg: 'rgba(42, 61, 102, 0.1)',
  },
  News: {
    color: 'rgba(40, 223, 153, 1)',
    bg: 'rgba(40, 223, 153, 0.1)',
  },
  'Photo & Video': {
    color: 'rgba(255, 187, 145, 1)',
    bg: 'rgba(255, 187, 145, 0.1)',
  },
  Productivity: {
    color: 'rgba(213, 64, 98, 1)',
    bg: 'rgba(213, 64, 98, 0.1)',
  },
  Reference: {
    color: 'rgba(143, 203, 241, 1)',
    bg: 'rgba(143, 203, 241, 0.1)',
  },
  Shopping: {
    color: 'rgba(9, 154, 151, 1)',
    bg: 'rgba(9, 154, 151, 0.1)',
  },
  'Social Networking': {
    color: 'rgba(98, 111, 230, 1)',
    bg: 'rgba(98, 111, 230, 0.1)',
  },
  Sports: {
    color: 'rgba(84, 77, 126, 1)',
    bg: 'rgba(84, 77, 126, 0.1)',
  },
  Travel: {
    color: 'rgba(114, 46, 209, 1)',
    bg: 'rgba(114, 46, 209, 0.1)',
  },
  Utilities: {
    color: 'rgba(21, 132, 103, 1)',
    bg: 'rgba(21, 132, 103, 0.1)',
  },
  Weather: {
    color: 'rgba(255, 157, 226, 1)',
    bg: 'rgba(255, 157, 226, 0.1)',
  },
};

export const SCREEN_TAG_OPTIONS = [
  'About',
  'Achievements',
  'Acknowledgement',
  'Action Options',
  'Activity & Notification',
  'Ads',
  'Alerts & Reminders',
  'AR',
  'Articles & News',
  'Audio Playback',
  'Booking Details',
  'Bookmarks & Favs',
  'Bots',
  'Browsers',
  'Calendars',
  'Camera',
  'Carts & Orders',
  'Charts',
  'Chats & Messaging',
  'Checkout',
  'Coach Marks',
  'Comments',
  'Confirmation',
  'Contacts',
  'Content Details',
  'Create',
  'Dark Mode',
  'Date & Time',
  'Discover & Explore',
  'Drawing',
  'Edit',
  'Empty States',
  'Error',
  'Event',
  'Feature Info',
  'Filter & Effects',
  'Filter & Sort',
  'Flag & Report',
  'Follow',
  'Full-screen Mode',
  'Games',
  'Grid Layout',
  'Groups & Rooms',
  'Guided Tour',
  'Help & Feedback',
  'Home',
  'Horizontal Layout',
  'Instruction',
  'Invite & Find Friends',
  'Launch Screens',
  'Leaderboard',
  'Libraries',
  'Like & Vote',
  'Loading',
  'Logins',
  'Maps',
  'Navigation',
  'Notes & Tasks',
  'Other Actions',
  'Payment Method',
  'Permission',
  'Photos',
  'Posts',
  'Pricing',
  'Privacy & Terms',
  'Product Details',
  'Profile & Account',
  'Progress',
  'Promos & Deals',
  'Pull to Refresh',
  'Quizzes',
  'Recipes & Menus',
  'Recording',
  'Remittance & Wallet',
  'Review Processes',
  'Reviews & Rating',
  'Search',
  'Settings',
  'Setups',
  'Share',
  'Signups',
  'Skeleton',
  'Stories',
  'Subscription',
  'Suggestions',
  'Tag Clouds',
  'Timeline & History',
  'Venue Details',
  'Verification',
  'Videos',
  'Walkthroughs',
];

export const APPLICATION_STATES = {
  PENDING: 'pending',
  READY: 'ready',
  INVALID: 'invalid',
};

export const FLOW_STATUS = {
  VALID: 'valid',
  NEEDS_VALIDATION: 'needs_validation',
  INVALID: 'invalid',
};
