import React from 'react';
import { Flex, Button, Text, Image } from 'rebass';

import twitterSrc from '../../assets/icons/share/twitter.svg';
import codeSrc from '../../assets/icons/share/code.svg';
import linkSrc from '../../assets/icons/share/link.svg';
import downloadSrc from '../../assets/icons/share/download.svg';
import theme from '../../theme';
import copy from '../../utils/copy';
import { Flow } from '../../types/common';
import { RouteFormatter } from '../../utils/routes';

type Props = {
  embed: (link: string) => void;
  flow: Flow;
  onDownload: (flow: Flow) => void;
  updateSuccessMessage: (message: string) => void;
};

const Share = (props: Props) => {
  const { flow, embed, updateSuccessMessage, onDownload } = props;
  const {
    application: { name: applicationName, id: applicationId, twitterName },
    appFlow: { name: appFlowName, id: appFlowId },
    id: versionFlowId,
  } = flow;

  // We wrap it in a method so that it's not called server side
  const twitterTag = twitterName.length ? ` by ${twitterName}` : '';
  const messagePrefix = `${appFlowName} flow on ${applicationName}${twitterTag}.%0AAutomated by @usewaldo.%0A`;
  const getLink = () =>
    `${window.location.origin}${RouteFormatter.versionFlowRoute(
      applicationName,
      applicationId,
      appFlowName,
      appFlowId,
      versionFlowId,
    )}`;

  const handleTwitterShare = () => {
    window.open(`https://twitter.com/share?ref_src=twsrc%5Etfw&url=${getLink()}&text=${messagePrefix}`);
  };

  const handleEmbed = () => {
    const link = `${window.location.origin}${RouteFormatter.embedRoute(
      applicationName,
      applicationId,
      appFlowName,
      appFlowId,
      versionFlowId,
    )}`;
    embed(link);
  };

  const handleDownload = () => {
    return onDownload(flow);
  };

  const copyToClipboard = () => {
    copy(getLink());
    updateSuccessMessage('Link has has been copied to your clipboard');
  };

  const commonButtonStyles = {
    backgroundColor: 'transparent',
    padding: 0,
    width: 40,
    ':hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.primary,
    },
    '&:hover > img': {
      filter: 'brightness(0) invert(1)',
    },
  };

  return (
    <Flex alignItems="flex-start" flexDirection="column">
      <Text mb={2} sx={{ ...theme.styles.h4, lineHeight: '28px' }}>
        Share this flow
      </Text>
      <Flex width={180} justifyContent="space-between">
        <Button onClick={() => handleTwitterShare()} variant="outline" style={{ ...commonButtonStyles }}>
          <Image src={twitterSrc} width={1 / 2} alt="share-twitter" />
        </Button>
        <Button onClick={copyToClipboard} variant="outline" style={{ ...commonButtonStyles }}>
          <Image src={linkSrc} width={1 / 2} alt="share-copy" />
        </Button>
        <Button onClick={handleDownload} variant="outline" style={{ ...commonButtonStyles }}>
          <Image src={downloadSrc} width={1 / 2} alt="share-download" />
        </Button>
        <Button onClick={handleEmbed} variant="outline" style={{ ...commonButtonStyles }}>
          <Image src={codeSrc} width={1 / 2} alt="share-embed" />
        </Button>
      </Flex>
    </Flex>
  );
};

export default Share;
