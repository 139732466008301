import { Dispatch } from 'redux';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Box, Text, Flex } from 'rebass';
import { match } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { History } from 'history';
import * as _ from 'lodash';

import ActionBuilder from '../../store/actions';
import { AppState, Flow, GetFlowsParams, User, AuthPage, InitialActionParams, Infos } from '../../types/common';
import ActionBoxContainer from './ActionBoxContainer';
import PoweredBy from '../../components/client/PoweredBy';
import FlowComponent from '../../components/common/FlowComponent';
import theme from '../../theme';
import { LEFT_BANNER_WIDTH, ITEMS_PER_PAGE } from '../../utils/constants';
import { generateQuery } from '../../utils/flows';
import { RouteFormatter, RouteParser, getPageNumberParams } from '../../utils/routes';
import PageManager from '../../components/client/PageManager';
import Loader from '../../components/common/Loader';
import Header from '../../components/client/Header';
import FlowHeader from '../../components/client/FlowHeader';

type Params = {
  pageNumber?: string;
};

type InitProps = {
  location: any;
};

type Props = InitProps & {
  appFlowNames: string[];
  dispatch: Dispatch;
  flows?: Flow[];
  getFlows: (params: GetFlowsParams) => void;
  getInfos: () => void;
  history: History;
  infos: Infos;
  match: match<Params>;
  navigateTo: (page?: AuthPage) => void;
  user: User | null;
};

class HomeContainer extends PureComponent<Props> {
  static initialAction = async ({ url }: InitialActionParams) => {
    const defaultResult = Promise.resolve();
    if (!url) {
      return defaultResult;
    }
    const { pageNumber } = RouteParser.homeRoute(url);
    return Promise.all([ActionBuilder.getFlows(getPageNumberParams(pageNumber)), ActionBuilder.getInfos()]);
  };

  componentDidMount = () => {
    this.checkForNotFound();
    this.fetchResources();
  };

  componentDidUpdate = (prevProps: Props) => {
    this.checkForNotFound();
    if (prevProps.location !== this.props.location) {
      this.fetchResources();
    }
  };

  checkForNotFound = () => {
    const { flows, history } = this.props;
    if (_.isEqual(flows, [])) {
      history.push(RouteFormatter.notFoundRoute());
    }
  };

  fetchResources = async () => {
    const {
      dispatch,
      location: { pathname },
    } = this.props;
    const actions = await HomeContainer.initialAction({ url: pathname });
    if (actions) {
      actions.map(dispatch);
    }
  };

  getPageNumber = () => parseInt(this.props.match.params.pageNumber || '1', 10);

  onPageNumberChange = (pageNumber: number) => this.props.history.push(`/${pageNumber}`);

  renderFlow = (flow: Flow) => {
    const {
      application: { name: appName, id: applicationId },
      appFlow: { name: appFlowName, id: appFlowId },
      id: versionFlowId,
    } = flow;
    return (
      <Flex width={1} mb={5} key={versionFlowId} flexDirection="column">
        <FlowHeader flow={flow} />
        <FlowComponent flow={flow} link={RouteFormatter.flowRoute(appName, applicationId, appFlowName, appFlowId)} />
      </Flex>
    );
  };

  renderFlows = (flows: Flow[]) => {
    const pageFlows = flows.slice(0, ITEMS_PER_PAGE);
    return pageFlows.length > 0 ? (
      <Box width={1} mb={5}>
        {pageFlows.map(this.renderFlow)}
      </Box>
    ) : (
      <Loader height="50vh" />
    );
  };

  renderActionBox = () => (
    <Box width={1} pl={[0, 0, LEFT_BANNER_WIDTH]}>
      <ActionBoxContainer />
      <Box width={1} mt={80} mb={4}>
        <PoweredBy />
      </Box>
    </Box>
  );

  renderNavigation = () => {
    const { appFlowNames } = this.props;
    return (
      <Box width={LEFT_BANNER_WIDTH} pr="80px" sx={{ display: ['none', 'none', 'block'] }}>
        <Text mb="24px" sx={{ ...theme.styles.h3, color: theme.colors.midnight }}>
          Flows
        </Text>
        {appFlowNames.map((name) => (
          <RouterLink key={name} to={RouteFormatter.appFlowNameRoute(name)} style={{ textDecoration: 'none' }}>
            <Text
              mb={3}
              sx={{
                ...theme.styles.h4,
                color: theme.colors.midnightLighten20,
                transitionDuration: '.3s',
                ':hover': { color: theme.colors.primary },
              }}
            >
              {_.capitalize(name)}
            </Text>
          </RouterLink>
        ))}
      </Box>
    );
  };

  renderPageManager = () => {
    const { infos } = this.props;
    return (
      <Box width={1} mb={5} pl={[0, 0, LEFT_BANNER_WIDTH]}>
        <PageManager
          pageNumber={this.getPageNumber()}
          onPageNumberChange={this.onPageNumberChange}
          itemsLength={infos.flowsCount - 1}
        />
      </Box>
    );
  };

  render = () => {
    const { flows, navigateTo, user, infos } = this.props;
    return (
      <Box width={1} pt={4}>
        {<Header infos={infos} navigateTo={navigateTo} loggedIn={!!user} />}
        <Box width={[95 / 100, 8 / 10]} m="auto">
          <Flex>
            {this.renderNavigation()}
            {this.renderFlows(flows || [])}
          </Flex>
          {this.renderPageManager()}
          {this.renderActionBox()}
        </Box>
      </Box>
    );
  };
}

const mapStateToProps = (state: AppState, props: InitProps) => {
  const {
    location: { pathname },
  } = props;
  // Here we don't extract pageNumber from params because this also runs server side
  const { pageNumber } = RouteParser.homeRoute(pathname);
  const request = generateQuery(getPageNumberParams(pageNumber));
  return {
    flows: state.flowRequests[request],
    infos: state.infos,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFlows: async (params: GetFlowsParams) => dispatch(await ActionBuilder.getFlows(params)),
  getInfos: async () => dispatch(await ActionBuilder.getInfos()),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
