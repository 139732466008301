import React from 'react';

type Props = {
  size?: number;
  width?: number;
  color?: string;
};

const Line = (props: Props) => {
  const { size = '100%', width = 4, color = 'black' } = props;
  return (
    <svg height={width} width={size}>
      <line x1="0" y1="0" x2={size} y2="0" style={{ stroke: color, strokeWidth: width }} />
    </svg>
  );
};

export default Line;
