import React from 'react';
import { Flex, Box, Text, Button } from 'rebass';
import theme from '../../theme';
import { formatDate } from '../../utils';

type Props = {
  semVer: string;
  createdAt: string;
  latest: boolean;
  selected: boolean;
  onClick: () => void;
};
const Version = (props: Props) => {
  const { semVer, latest, createdAt, selected, onClick } = props;
  const prefix = latest ? 'Latest | ' : '';
  const { primary, pearl } = theme.colors;
  const color = selected ? primary : pearl;
  const opacity = selected ? 1 : 0.4;
  const displayDate = formatDate(createdAt);
  return (
    <Flex justifyContent="center" alignItems="flex-end" minWidth={[100, 160]}>
      <Button
        onClick={onClick}
        height={130}
        px={0}
        sx={{
          bg: 'transparent',
          color,
          boxShadow: 'none',
          ':hover': { bg: 'transparent' },
        }}
      >
        <Flex
          px={3}
          width={[100, 160]}
          flexDirection="column"
          sx={{
            ':hover': { color: 'primary' },
            ':hover > div': { bg: primary, opacity: 1 },
          }}
        >
          <Text
            as="p"
            width={1}
            mb={1}
            sx={{ fontSize: [1, 2], fontWeight: 500, textAlign: 'center', textDecoration: 'none' }}
          >{`${prefix}${semVer}`}</Text>
          <Box width={1} height={10} my={1} sx={{ bg: color, borderRadius: 10, opacity }} />
          <Text as="p" mt={1} width={1} sx={{ fontSize: [0, 1], fontWeight: 400, textAlign: 'center' }}>
            {displayDate}
          </Text>
        </Flex>
      </Button>
    </Flex>
  );
};

export default Version;
