import React from 'react';
import { Flex, Text, Image, Link as RebassLink } from 'rebass';
import { Link as RouterLink } from 'react-router-dom';

import { RouteFormatter } from '../../utils/routes';
import { theme } from '../../theme';
import Badge from './Badge';
import { Flow } from '../../types/common';
import { formatAppStoreUrl } from '../../utils';
import WaldoBadge from './WaldoBadge';

type Props = {
  flow: Flow;
};

const FlowHeader = ({
  flow: {
    application: { name: applicationName, id: applicationId, appStoreId },
    appFlow: { name: appFlowName, displayName },
    version: { iconUrl, semVer, categories },
  },
}: Props) => (
  <Flex pl={1} pb={4} flexDirection="column">
    <Flex mb={2} alignItems="center">
      <RebassLink target="_blank" href={formatAppStoreUrl(applicationName, appStoreId)}>
        <Image mr={2} variant="iconShadow" src={iconUrl} width={32} sx={{ borderRadius: 6 }} alt="icon" />
      </RebassLink>
      <RouterLink to={RouteFormatter.appFlowNameRoute(appFlowName)} style={{ textDecoration: 'none' }}>
        <Text sx={{ fontSize: [1, 2, 3], fontWeight: 500, color: theme.colors.midnight }}>{displayName}</Text>
      </RouterLink>
      <Text mx={1} sx={{ fontSize: [1, 2, 3], fontWeight: 500, color: theme.colors.smokeDarken20 }}>
        on
      </Text>
      <RouterLink
        to={RouteFormatter.applicationRoute(applicationName, applicationId, semVer, 'latest')}
        style={{ textDecoration: 'none' }}
      >
        <Text sx={{ fontSize: [1, 2, 3], fontWeight: 500, color: theme.colors.midnight }}>{applicationName}</Text>
      </RouterLink>
    </Flex>
    <Flex alignItems={['flex-start', 'center']} flexDirection={['column', 'row']}>
      <WaldoBadge />
      <Badge text={categories[0]} ml={[0, 3]} mt={[2, 0]} />
    </Flex>
  </Flex>
);

export default FlowHeader;
