import {
  GET_ADMIN_FLOWS_SUCCESS,
  GET_ADMIN_FLOWS_ERROR,
  UPDATE_FLOW_SUCCESS,
  UPDATE_FLOW_ERROR,
  GET_FLOWS_SUCCESS,
  GET_APP_FLOW_NAMES_SUCCESS,
  GET_APP_FLOW_NAMES_ERROR,
  PATCH_APP_FLOW_NAME,
  GET_INFOS_SUCCESS,
  GET_INFOS_ERROR,
} from './actionTypes';

export const receivedAdminFlows = (payload: any) => ({
  type: GET_ADMIN_FLOWS_SUCCESS,
  payload,
});

export const adminFlowsError = (payload: any) => ({
  type: GET_ADMIN_FLOWS_ERROR,
  payload,
});

export const receivedFlows = (payload: any) => ({
  type: GET_FLOWS_SUCCESS,
  payload,
});

export const receivedAppFlowNames = (payload: any) => ({
  type: GET_APP_FLOW_NAMES_SUCCESS,
  payload,
});

export const appFlowNamesError = (payload: any) => ({
  type: GET_APP_FLOW_NAMES_ERROR,
  payload,
});

export const patchedAppFlowName = (payload: any) => ({
  type: PATCH_APP_FLOW_NAME,
  payload,
});

export const updatedFlow = (payload: any) => ({
  type: UPDATE_FLOW_SUCCESS,
  payload,
});

export const updateFlowError = (payload: any) => ({
  type: UPDATE_FLOW_ERROR,
  payload,
});

export const receivedInfos = (payload: any) => ({
  type: GET_INFOS_SUCCESS,
  payload,
});

export const infosError = (payload: any) => ({
  type: GET_INFOS_ERROR,
  payload,
});
