import { ArchiveApi, AuthApi } from '../../api/api';
import {
  receivedAdminFlows,
  receivedFlows,
  adminFlowsError,
  updatedFlow,
  updateFlowError,
  receivedAppFlowNames,
  appFlowNamesError,
  receivedInfos,
  infosError,
} from './flow';
import {
  receivedAdminApps,
  adminAppsError,
  addedApplication,
  addApplicationError,
  receivedApps,
  appsError,
  receivedAdminApplication,
  adminApplicationError,
  patchedApplication,
  patchApplicationError,
} from './app';
import { receivedAdminUserName, adminUserNameError, receivedUser, userError } from './user';
import { PatchFlowRequest, GetFlowsParams, GetAppsParams, ApplicationState, PatchAppFlow } from '../../types/common';
import { receivedAppVersions } from './appVersion';
import { generateQuery } from '../../utils/flows';
import { REQUEST_STATUS } from '../../utils/constants';
import { getErrorStatus } from '../../utils/errors';
export default class ActionBuilder {
  static getAdminFlows = async (adminAuthToken?: string) => {
    try {
      const flows = await ArchiveApi.getAdminFlows(adminAuthToken);
      return receivedAdminFlows(flows);
    } catch (err) {
      return adminFlowsError(err);
    }
  };

  static getInfos = async () => {
    try {
      const infos = await ArchiveApi.getInfos();
      return receivedInfos(infos);
    } catch (err) {
      return infosError(err);
    }
  };

  static getFlows = async (params?: GetFlowsParams) => {
    const request = generateQuery(params);
    try {
      const flows = await ArchiveApi.getFlows(params);
      return receivedFlows({ flows, request, status: REQUEST_STATUS.SUCCESS });
    } catch (err) {
      return receivedFlows({ flows: [], request, status: getErrorStatus(err) });
    }
  };

  static getFlow = async (flowId: string) => {
    const request = generateQuery({ versionFlowId: flowId });
    try {
      const flow = await ArchiveApi.getFlow(flowId);
      return receivedFlows({ flows: [flow], request, status: REQUEST_STATUS.SUCCESS });
    } catch (err) {
      return receivedFlows({ flows: [], request, status: getErrorStatus(err) });
    }
  };

  static getAppFlowNames = async () => {
    try {
      const appFlowNames = await ArchiveApi.getAppFlowNames();
      return receivedAppFlowNames(appFlowNames);
    } catch (err) {
      return appFlowNamesError(err);
    }
  };

  static getAppVersions = async (applicationId: string) => {
    try {
      const versions = await ArchiveApi.getAppVersions(applicationId);
      return receivedAppVersions({ status: REQUEST_STATUS.SUCCESS, applicationId, versions });
    } catch (err) {
      return receivedAppVersions({ status: getErrorStatus(err), applicationId });
    }
  };

  static updateFlow = async (patchFlowRequest: PatchFlowRequest, adminAuthToken?: string) => {
    try {
      const newFlow = await ArchiveApi.updateFlow(patchFlowRequest, adminAuthToken);
      return updatedFlow(newFlow);
    } catch (err) {
      return updateFlowError(err);
    }
  };

  static getAdminApps = async (adminAuthToken?: string) => {
    try {
      const apps = await ArchiveApi.getAdminApps(adminAuthToken);
      return receivedAdminApps(apps);
    } catch (err) {
      return adminAppsError(err);
    }
  };

  static patchApplication = async (applicationId: string, state: ApplicationState, adminAuthToken?: string) => {
    try {
      const apps = await ArchiveApi.patchApplication(applicationId, state, adminAuthToken);
      return patchedApplication(apps);
    } catch (err) {
      return patchApplicationError(err);
    }
  };

  static getAdminApplication = async (applicationId: string, adminAuthToken?: string) => {
    try {
      const apps = await ArchiveApi.getAdminApplication(applicationId, adminAuthToken);
      return receivedAdminApplication(apps);
    } catch (err) {
      return adminApplicationError(err);
    }
  };

  static getApps = async (params?: GetAppsParams) => {
    try {
      const apps = await ArchiveApi.getApps(params);
      return receivedApps(apps);
    } catch (err) {
      return appsError(err);
    }
  };

  static addApplication = async (appStoreUrl: string, adminAuthToken?: string) => {
    try {
      const apps = await ArchiveApi.addApplication(appStoreUrl, adminAuthToken);
      return addedApplication(apps);
    } catch (err) {
      return addApplicationError(err);
    }
  };

  static getAdminUserName = async (adminAuthToken?: string) => {
    try {
      const adminUserName = await ArchiveApi.getAdminUserName(adminAuthToken);
      return receivedAdminUserName(adminUserName);
    } catch (err) {
      return adminUserNameError(err);
    }
  };

  static getUser = async (authToken?: string) => {
    if (!authToken) {
      return userError(new Error('no auth token'));
    }

    try {
      const user = await AuthApi.getUser(authToken);
      return receivedUser(user);
    } catch (err) {
      return userError(err);
    }
  };

  static patchAppFlow = async (appFlowId: string, update: PatchAppFlow, adminAuthToken?: string) => {
    try {
      await ArchiveApi.patchAppFlow(appFlowId, update, adminAuthToken);
      return ActionBuilder.getAdminFlows(adminAuthToken);
    } catch (err) {
      return adminFlowsError(err);
    }
  };
}
