import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminLogin from './AdminLogin';
import { isBrowser, redirectTo } from '../../utils';
import AdminLayout from './AdminLayout';

type Props = {
  adminloggedIn: boolean;
};

const AdminAuthSwitch = (props: Props) => {
  const { adminloggedIn } = props;
  if (!adminloggedIn && isBrowser() && window.location.pathname !== '/login') {
    // redirect user to login view
    redirectTo('/login');
  }
  if (adminloggedIn && isBrowser() && window.location.pathname === '/login') {
    // redirect user to main view
    redirectTo('/');
  }

  return (
    <Switch>
      <Route exact path="/login" component={AdminLogin} />
      <Route path="/" render={() => <AdminLayout adminloggedIn={adminloggedIn} />} />
    </Switch>
  );
};

export default AdminAuthSwitch;
