import React from 'react';
import { Modal } from 'antd';

import { AuthPage } from '../../types/common';
import SignupContainer from '../../containers/client/SignupContainer';
import LoginContainer from '../../containers/client/LoginContainer';
import ConfirmEmail from './ConfirmEmail';
import ForgotContainer from '../../containers/client/ForgotContainer';
import ResetCheckEmail from './ResetCheckEmail';

type Props = {
  getUser: (authToken: string) => void;
  page: AuthPage;
  block: boolean;
  navigateTo: (page?: AuthPage) => void;
};

// FIXME - This should be a container and have the form onChange + onSubmit logic
// We could also create an AuthContentComponent that would display the UI (pretty similar in all scenarios)
const Auth = ({ page, navigateTo, getUser, block }: Props) => (
  <Modal
    open={!!page}
    onCancel={() => !block && navigateTo()}
    width={page === 'signup' || page === 'confirm' ? 1000 : 500}
    bodyStyle={{ padding: 0, display: 'flex', justifyContent: 'center' }}
    footer={null}
    zIndex={1001}
    closable={!block}
  >
    {page === 'signup' && <SignupContainer navigateTo={navigateTo} />}
    {page === 'login' && <LoginContainer navigateTo={navigateTo} getUser={getUser} />}
    {page === 'confirm' && <ConfirmEmail />}
    {page === 'forgot-password' && <ForgotContainer navigateTo={navigateTo} />}
    {page === 'reset-check' && <ResetCheckEmail />}
  </Modal>
);

export default Auth;
