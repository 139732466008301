import React from 'react';
import { Image, Link as RebassLink } from 'rebass';

import { theme } from '../../theme';
import Badge from './Badge';
import waldoIconSrc from '../../assets/icons/waldo-icon.svg';
import { WALDO_LANDING_URL } from '../../utils/constants';

const WaldoBadge = () => (
  <RebassLink target="_blank" href={WALDO_LANDING_URL}>
    <Badge
      text="Automated by Waldo"
      color={theme.colors.primaryVideo}
      textColor={theme.colors.primary}
      fontWeight={500}
    >
      <Image src={waldoIconSrc} mr={2} mb={1} />
    </Badge>
  </RebassLink>
);

export default WaldoBadge;
