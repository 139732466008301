import * as _ from 'lodash';

import { Flow, AppVersion, Version } from '../types/common';

export const applicationFlowsByVersion = (applicationFlows: Flow[], versionId: string): Flow[] => {
  const groupedApplicationFlows = _.groupBy(applicationFlows, 'version.id');
  return groupedApplicationFlows[versionId] || [];
};

export const getLatestVersion = (appVersions: (AppVersion | Version)[]): string | null => {
  if (appVersions.length === 0) {
    return null;
  }
  const sortedVersions = _.sortBy(appVersions, (item) => new Date(item.createdAt));
  const latestVersion = _.last(sortedVersions);
  return latestVersion ? latestVersion.id : null;
};

export const sortVersions = (appVersions: (AppVersion | Version)[]): (AppVersion | Version)[] =>
  _.sortBy(appVersions, (item) => new Date(item.createdAt));

export const generateQuery = (params = {}) =>
  JSON.stringify(
    _.keys(params)
      .sort()
      .reduce((acc, key) => ({ ...acc, [key]: params[key] }), {}),
  );
