import React from 'react';
import { Image, Flex } from 'rebass';

import logo from '../../assets/logos/logo.svg';
import logoWhite from '../../assets/logos/logo-white.svg';
import { Link } from 'react-router-dom';

type Props = {
  withLink?: boolean;
  white?: boolean;
  width?: number;
};

const Logo = ({ withLink, white, width }: Props) => {
  const renderLogo = () => (
    <Flex height="100%" alignItems="center">
      <Image width={width || 130} src={white ? logoWhite : logo} alt="logo" />
    </Flex>
  );

  return withLink ? (
    <Link to={'/'} style={{ textDecoration: 'none' }}>
      {renderLogo()}
    </Link>
  ) : (
    renderLogo()
  );
};

export default Logo;
