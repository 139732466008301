import * as _ from 'lodash';
import { Page, PageMeta } from '../types/common';
import { RouteParser } from './routes';

export const pathToPage = (path: string): Page => {
  if (path === '/') {
    return 'HOME';
  } else if (path === '/errors/404') {
    return 'NOT_FOUND';
  } else if (path.startsWith('/appFlowName/')) {
    return 'APP_FLOW_NAME';
  } else if (path.startsWith('/app/')) {
    return 'APP';
  } else if (path.startsWith('/flow/')) {
    return 'FLOW';
  } else if (path.includes('/versionflow-')) {
    return 'VERSION_FLOW';
  }
  return 'HOME';
};

const sanitizeName = (name: string) => _.capitalize(decodeURI(name).replace('-', ' '));

export const pathToMeta = (path: string): PageMeta => {
  const page = pathToPage(path);
  const DEFAULT_META = {
    title: 'UXArchive - Made by Waldo',
    description:
      "UXArchive the world's largest library of mobile user flows. Be inspired to design the best user experiences.",
  };
  if (page === 'HOME') {
    return DEFAULT_META;
  } else if (page === 'NOT_FOUND') {
    return {
      title: '404 | UXArchive',
      description:
        "UXArchive the world's largest library of mobile user flows. Be inspired to design the best user experiences.",
    };
  } else if (page === 'APP_FLOW_NAME') {
    const { appFlowName } = RouteParser.appFlowRoute(path);
    const cleanAppFlowName = sanitizeName(appFlowName);
    return {
      title: `${cleanAppFlowName} Flows | UXArchive`,
      description: `Discover all ${cleanAppFlowName} flows on UXArchive and be inspired to design the best mobile user experiences.`,
    };
  } else if (page === 'APP') {
    const { appName } = RouteParser.applicationRoute(path);
    const cleanAppName = sanitizeName(appName);
    return {
      title: `${cleanAppName} Flows | UXArchive`,
      description: `Discover all the user flows on ${cleanAppName} on UXArchive and be inspired to design the best mobile user experiences.`,
    };
  } else if (page === 'FLOW') {
    const { appName, appFlowName } = RouteParser.flowRoute(path);
    const cleanAppFlowName = sanitizeName(appFlowName);
    const cleanAppName = sanitizeName(appName);
    return {
      title: `${cleanAppFlowName} flow on ${cleanAppName} | UXArchive`,
      description: `Discover how ${cleanAppName} did its ${cleanAppFlowName} on UXArchive and be inspired to design the best mobile user experiences.`,
    };
  } else if (page === 'VERSION_FLOW') {
    const { appName, appFlowName } = RouteParser.versionFlowRoute(path);
    const cleanAppFlowName = sanitizeName(appFlowName);
    const cleanAppName = sanitizeName(appName);
    return {
      title: `${cleanAppFlowName} flow on ${cleanAppName} | UXArchive`,
      description: `Discover how ${cleanAppName} did its ${cleanAppFlowName} on UXArchive and be inspired to design the best mobile user experiences.`,
    };
  }
  return DEFAULT_META;
};
