import React from 'react';
import { Flex, Text } from 'rebass';

import theme from '../../theme';

const ResetCheckEmail = () => (
  <Flex width={500} px={[3, 5]} py={[4, 5]} flexDirection="column" alignItems="center">
    <Text mb={3} sx={{ ...theme.styles.h1, fontWeight: 'bold' }}>
      Check your email
    </Text>
    <Text mb={3} sx={{ ...theme.styles.p, textAlign: 'center' }}>
      Please check your email, it will contain instructions about how to reset your password.
    </Text>
  </Flex>
);

export default ResetCheckEmail;
