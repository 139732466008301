import React, { PureComponent } from 'react';
import { Flex, Text, Button } from 'rebass';
import { Input } from '@rebass/forms';
import { Dispatch } from 'redux';

import theme from '../../theme';
import notifActionBuilder from '../../store/actions/notif';
import { connect } from 'react-redux';
import { ArchiveApi } from '../../api/api';

type Props = {
  updateSuccessMessage: (message: string) => void;
  updateErrorMessage: (message: string) => void;
};

type OwnState = {
  email: string;
};

class ActionBoxContainer extends PureComponent<Props, OwnState> {
  state = {
    email: '',
  };

  formRef?: HTMLFormElement;

  handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!this.formRef) return;
    const valid = this.formRef.reportValidity();
    if (valid) {
      await this.onSubmit();
    }
  };

  onSubmit = async () => {
    const { updateErrorMessage, updateSuccessMessage } = this.props;
    try {
      await ArchiveApi.subscribeUser({ email: this.state.email, type: 'newsletter' });
      this.setState({ email: '' });
      updateSuccessMessage('You have successfully subscribed to the newsletter!');
    } catch (err) {
      updateErrorMessage('Something went wrong');
    }
  };

  onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.target.value });

  setFormRef = (ref: HTMLFormElement) => {
    if (ref) {
      this.formRef = ref;
    }
  };

  render = () => (
    <Flex
      width={1}
      py={[3, 4]}
      px={[1, 4]}
      justifyContent="space-around"
      alignItems="center"
      bg={theme.colors.primary}
      flexDirection="column"
      sx={{ borderRadius: 10, textAlign: 'center' }}
    >
      <Text color={theme.colors.smokeLighten80} sx={{ fontWeight: 'normal', fontSize: 1 }}>
        {'GET THE LATEST'}
      </Text>
      <Text mb={3} color="white" fontSize={28} fontWeight={500}>
        {'UX trends from the world’s top mobile apps'}
      </Text>
      <Flex
        width={1}
        as="form"
        ref={this.setFormRef}
        justifyContent="center"
        alignItems="center"
        flexDirection={['column', 'row']}
      >
        <Input
          maxWidth={180}
          height={42}
          mr={[0, 3]}
          mb={[3, 0]}
          id="email"
          name="email"
          onChange={this.onEmailChange}
          placeholder="Enter email address"
          required
          type="email"
          value={this.state.email}
          variant="input"
          bg="white"
          sx={{ fontSize: 1 }}
        />
        <Button variant="secondary" onClick={this.handleSubmit}>
          Subscribe
        </Button>
      </Flex>
    </Flex>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSuccessMessage: (message: string) => notifActionBuilder.updateSuccessMessage(message, dispatch),
  updateErrorMessage: (message: string) => notifActionBuilder.updateErrorMessage(message, dispatch),
});

export default connect(null, mapDispatchToProps)(ActionBoxContainer);
