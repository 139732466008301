import { combineReducers } from 'redux';
import * as _ from 'lodash';
import { identify } from '../utils/track';

import {
  GET_ADMIN_FLOWS_SUCCESS,
  GET_ADMIN_FLOWS_ERROR,
  GET_ADMIN_APPS_SUCCESS,
  GET_ADMIN_APPS_ERROR,
  GET_ADMIN_USERNAME_SUCCESS,
  GET_ADMIN_USERNAME_ERROR,
  UPDATE_FLOW_SUCCESS,
  UPDATE_FLOW_ERROR,
  GET_FLOWS_SUCCESS,
  GET_APP_VERSIONS_SUCCESS,
  GET_APP_FLOW_NAMES_SUCCESS,
  GET_APP_FLOW_NAMES_ERROR,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_ERROR,
  GET_APPS_SUCCESS,
  GET_APPS_ERROR,
  UPDATE_SUCCESS_MESSAGE,
  UPDATE_ERROR_MESSAGE,
  GET_ADMIN_APPLICATION_SUCCESS,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  LOGOUT_USER,
  PATCH_APPLICATION_ERROR,
  PATCH_APPLICATION_SUCCESS,
  GET_INFOS_SUCCESS,
  GET_INFOS_ERROR,
} from './actions/actionTypes';
import { Flow, FlowsState, AppVersionsState, App, Infos } from '../types/common';
import { REQUEST_STATUS } from '../utils/constants';

type Action = {
  type: string;
  payload: any;
};

const successMessageReducer = (state = '', action: Action) => {
  const { type, payload } = action;
  if (type === UPDATE_SUCCESS_MESSAGE) {
    return payload;
  } else if (type === UPDATE_ERROR_MESSAGE) {
    return '';
  }

  return state;
};

const errorMessageReducer = (state = '', action: Action) => {
  const { type, payload } = action;
  if (type === UPDATE_ERROR_MESSAGE) {
    return payload;
  } else if (type === UPDATE_SUCCESS_MESSAGE) {
    return '';
  }

  return state;
};

const adminFlowsReducer = (state: Flow[] = [], action: Action) => {
  const { type, payload } = action;
  if (type === GET_ADMIN_FLOWS_SUCCESS) {
    return payload;
  } else if (type === UPDATE_FLOW_SUCCESS) {
    return state.map((flow) => (flow.id === payload.id ? payload : flow));
  } else if (type === GET_ADMIN_FLOWS_ERROR || type === UPDATE_FLOW_ERROR) {
    return state;
  }

  return state;
};

const adminAppsReducer = (state: App[] = [], action: Action) => {
  const { type, payload } = action;
  if (type === GET_ADMIN_APPS_SUCCESS) {
    return payload;
  } else if (type === GET_ADMIN_APPLICATION_SUCCESS) {
    const app = state.find(({ id }) => id === payload.id);
    if (!app) {
      return [...state, payload];
    }
  } else if (type === ADD_APPLICATION_SUCCESS) {
    return [...state, payload];
  } else if (type === PATCH_APPLICATION_SUCCESS) {
    return [...state.filter(({ id }: App) => id !== payload.id), payload];
  } else if (type === GET_ADMIN_APPS_ERROR || type === ADD_APPLICATION_ERROR || type === PATCH_APPLICATION_ERROR) {
    return state;
  }

  return state;
};

const infosReducer = (
  state: Infos = {
    applicationsCount: -1,
    flowsCount: -1,
    stepsCount: -1,
  },
  action: Action,
) => {
  const { type, payload } = action;
  if (type === GET_INFOS_SUCCESS) {
    return payload;
  } else if (type === GET_INFOS_ERROR) {
    return state;
  }

  return state;
};

const appsReducer = (state: App[] = [], action: Action) => {
  const { type, payload } = action;
  if (type === GET_APPS_SUCCESS) {
    return payload.filter(({ state, numFlowsValid }: App) => state !== 'invalid' && numFlowsValid > 0);
  } else if (type === GET_APPS_ERROR) {
    return state;
  }

  return state;
};

const appVersionsReducer = (state: AppVersionsState = {}, action: Action) => {
  const { type, payload } = action;
  if (type === GET_APP_VERSIONS_SUCCESS) {
    const { status, applicationId, versions } = payload;
    if (status === REQUEST_STATUS.SUCCESS) {
      return {
        ...state,
        [applicationId]: versions,
      };
    } else if (status === REQUEST_STATUS.NOT_FOUND_ERROR) {
      return {
        ...state,
        [applicationId]: null,
      };
    }
  }

  return state;
};

const flowRequestsReducer = (state: FlowsState = {}, action: Action) => {
  const { type } = action;
  if (type === GET_FLOWS_SUCCESS) {
    const {
      payload: { flows, request, status },
    } = action;
    if (status === REQUEST_STATUS.NOT_FOUND_ERROR) {
      return {
        ...state,
        [request]: [],
      };
    } else if (status === REQUEST_STATUS.SUCCESS) {
      const cleanFlows = flows.map((item: Flow) => ({
        ...item,
        appFlow: {
          ...item.appFlow,
          displayName: _.capitalize(item.appFlow.name),
        },
      }));
      return {
        ...state,
        [request]: cleanFlows,
      };
    }
  }

  return state;
};

const appFlowNamesReducer = (state: string[] = [], action: Action) => {
  const { type, payload } = action;
  if (type === GET_APP_FLOW_NAMES_SUCCESS) {
    return payload.sort();
  } else if (type === GET_APP_FLOW_NAMES_ERROR) {
    return state;
  }

  return state;
};

const adminUserReducer = (state = '', action: Action) => {
  const { type, payload } = action;
  if (type === GET_ADMIN_USERNAME_SUCCESS) {
    return payload;
  } else if (type === GET_ADMIN_USERNAME_ERROR) {
    return state;
  } else if (type === LOGOUT_USER) {
    return '';
  }

  return state;
};

const userReducer = (state = null, action: Action) => {
  const { type, payload } = action;
  if (type === GET_USER_SUCCESS) {
    identify(payload.id);
    return payload;
  } else if (type === GET_USER_ERROR) {
    return state;
  } else if (type === LOGOUT_USER) {
    return null;
  }

  return state;
};

const reducers = {
  adminApps: adminAppsReducer,
  adminFlows: adminFlowsReducer,
  adminUserName: adminUserReducer,
  appFlowNames: appFlowNamesReducer,
  apps: appsReducer,
  appVersions: appVersionsReducer,
  errorMessage: errorMessageReducer,
  flowRequests: flowRequestsReducer,
  successMessage: successMessageReducer,
  user: userReducer,
  infos: infosReducer,
};

export default combineReducers(reducers);
