const { FORCE_HTTPS } = process.env;

const nodeEnv = process.env.NODE_ENV_ORIGINAL || 'development';

export const serverConfig = { ...process.env, FORCE_HTTPS, NODE_ENV: nodeEnv };

const configWhitelist = new Set(['NODE_ENV', 'GIT_SHA', 'PORT']);

export const clientConfig = Object.keys(serverConfig).reduce((acc, key) => {
  if (configWhitelist.has(key) || key.startsWith('RAZZLE')) {
    acc[key] = serverConfig[key];
  }
  return acc;
}, {});
