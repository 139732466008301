export const GET_ADMIN_FLOWS_SUCCESS = 'GET_ADMIN_FLOWS_SUCCESS';
export const GET_ADMIN_FLOWS_ERROR = 'GET_ADMIN_FLOWS_ERROR';
export const GET_FLOWS_SUCCESS = 'GET_FLOWS_SUCCESS';
export const UPDATE_FLOW_SUCCESS = 'UPDATE_FLOW_SUCCESS';
export const UPDATE_FLOW_ERROR = 'UPDATE_FLOW_ERROR';

export const GET_APP_FLOW_NAMES_SUCCESS = 'GET_APP_FLOW_NAMES_SUCCESS';
export const GET_APP_FLOW_NAMES_ERROR = 'GET_APP_FLOW_NAMES_ERROR';

export const GET_ADMIN_APPS_SUCCESS = 'GET_ADMIN_APPS_SUCCESS';
export const GET_ADMIN_APPS_ERROR = 'GET_ADMIN_APPS_ERROR';
export const GET_ADMIN_APPLICATION_SUCCESS = 'GET_ADMIN_APPLICATION_SUCCESS';
export const GET_ADMIN_APPLICATION_ERROR = 'GET_ADMIN_APPLICATION_ERROR';
export const GET_APPS_SUCCESS = 'GET_APPS_SUCCESS';
export const GET_APPS_ERROR = 'GET_APPS_ERROR';

export const ADD_APPLICATION_SUCCESS = 'ADD_APPLICATION_SUCCESS';
export const ADD_APPLICATION_ERROR = 'ADD_APPLICATION_ERROR';

export const PATCH_APPLICATION_SUCCESS = 'PATCH_APPLICATION_SUCCESS';
export const PATCH_APPLICATION_ERROR = 'PATCH_APPLICATION_ERROR';

export const GET_APP_VERSIONS_SUCCESS = 'GET_APP_VERSIONS_SUCCESS';

export const GET_ADMIN_USERNAME_SUCCESS = 'GET_ADMIN_USERNAME_SUCCESS';
export const GET_ADMIN_USERNAME_ERROR = 'GET_ADMIN_USERNAME_ERROR';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';
export const UPDATE_SUCCESS_MESSAGE = 'UPDATE_SUCCESS_MESSAGE';

export const LOGOUT_USER = 'LOGOUT_USER';

export const PATCH_APP_FLOW_NAME = 'PATCH_APP_FLOW_NAME';

export const GET_INFOS_SUCCESS = 'GET_INFOS_SUCCESS';
export const GET_INFOS_ERROR = 'GET_INFOS_ERROR';
