import React, { PureComponent } from 'react';
import { Flex, Button, Text, Box, Image, Link as RebassLink } from 'rebass';
import { History } from 'history';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Drawer } from 'antd';

import Logo from './Logo';
import { RouteFormatter } from '../../utils/routes';
import { App, AuthPage, User } from '../../types/common';
import SearchBar from './SearchBar';
import theme from '../../theme';
import { deleteClientCookie } from '../../utils/token';
import { WALDO_SESSIONS_URL } from '../../utils/constants';
import menuIconSrc from '../../assets/icons/menu-icon.svg';

type Props = RouteComponentProps & {
  apps: App[];
  history: History;
  logoutUser: () => void;
  navigateTo: (page: AuthPage) => void;
  user: User | null;
};

type OwnState = {
  isScrolled: boolean;
  visible: boolean;
};

class Navbar extends PureComponent<Props, OwnState> {
  state = {
    isScrolled: false,
    visible: false,
  };

  componentDidMount = () => window.addEventListener('scroll', this.listenToScroll);

  handleApplicationSelect = ({ name: applicationName, id: applicationId }: App) => {
    const { history, navigateTo, user } = this.props;
    this.toggleDrawerIfNecessary();
    if (!user) {
      navigateTo('signup');
    } else {
      history.push(RouteFormatter.applicationRoute(applicationName, applicationId));
    }
  };

  toggleDrawerIfNecessary = () => {
    if (this.state.visible) {
      this.toggleDrawer();
    }
  };

  handleLogin = () => {
    this.toggleDrawerIfNecessary();
    this.props.navigateTo('login');
  };

  handleSignup = () => {
    this.toggleDrawerIfNecessary();
    this.props.navigateTo('signup');
  };

  listenToScroll = () => {
    const isAboveScrollLimit = window.pageYOffset > 100;
    if (!this.state.isScrolled && isAboveScrollLimit) {
      this.setState({ isScrolled: true });
    } else if (this.state.isScrolled && !isAboveScrollLimit) {
      this.setState({ isScrolled: false });
    }
  };

  logout = () => {
    deleteClientCookie();
    this.props.logoutUser();
  };

  toggleDrawer = () => this.setState({ visible: !this.state.visible });

  renderLoggedInButtons = () => (
    <Flex flexDirection={['column', 'row']}>
      <Button variant="text" onClick={this.logout}>
        <Text
          pt="1px"
          sx={{
            ...theme.styles.h4,
            color: theme.colors.primary,
            fontWeight: 500,
            ':hover': { textDecoration: 'underline' },
          }}
        >
          Sign Out
        </Text>
      </Button>
    </Flex>
  );

  renderLoggedOutButtons = () => (
    <Flex flexDirection={['column', 'row']}>
      <Button minWidth={60} mt={[3, 0]} mb={[2, 0]} width={[230, 'auto']} variant="text" onClick={this.handleLogin}>
        <Text
          pt="1px"
          sx={{
            ...theme.styles.h4,
            color: theme.colors.primary,
            fontWeight: 500,
            ':hover': { textDecoration: 'underline' },
          }}
        >
          Login
        </Text>
      </Button>
      <Button
        ml={[2, '6px']}
        mt="1px"
        minWidth={100}
        width={[230, 'auto']}
        variant="primary"
        onClick={this.handleSignup}
      >
        Sign Up
      </Button>
      <RebassLink ml={[2, 4]} mb={[3, 0]} mt={[3, '2px']} href={WALDO_SESSIONS_URL} target="_blank">
        <Button variant="outline" width={[230, 'auto']}>
          Submit your app
        </Button>
      </RebassLink>
    </Flex>
  );

  renderBurgerButton = () => (
    <Box mr={0} mb={2} display={['block', 'none', 'none']}>
      <Button
        bg="transparent"
        sx={{ boxShadow: 'none', ':hover': { bg: 'transparent' } }}
        style={{ padding: 0 }}
        onClick={this.toggleDrawer}
      >
        <Image width={30} src={menuIconSrc} />
      </Button>
      <Drawer
        title="Menu"
        placement="right"
        closable={false}
        onClose={this.toggleDrawer}
        open={this.state.visible}
        headerStyle={{ textAlign: 'center' }}
        bodyStyle={{
          paddingTop: 10,
          paddingLeft: 0,
          paddingRight: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SearchBar applications={this.props.apps} onSelect={this.handleApplicationSelect} />
        {this.renderActionButtons()}
      </Drawer>
    </Box>
  );

  renderActionButtons = () => (this.props.user ? this.renderLoggedInButtons() : this.renderLoggedOutButtons());

  renderRightContent = () => (
    <Box display={['none', 'none', 'flex']}>
      <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap-reverse">
        <Flex mt={[3, 3, 0]} alignSelf="center">
          <SearchBar applications={this.props.apps} onSelect={this.handleApplicationSelect} />
        </Flex>
        <Flex mt={[3, 3, 0]} alignItems="center">
          {this.renderActionButtons()}
        </Flex>
      </Flex>
    </Box>
  );

  render = () => {
    const scrolledStyle = this.state.isScrolled
      ? {
          boxShadow: '0px 1px 0px rgba(152, 157, 189, 0.2)',
        }
      : {};
    return (
      <Flex
        width={1}
        justifyContent="center"
        height={64}
        m="auto"
        bg={theme.colors.white}
        sx={{ position: 'fixed', top: 0, zIndex: 10, ...scrolledStyle }}
      >
        <Flex width={[9 / 10, 9 / 10, 8 / 10]} py={[3, 2, 1]} justifyContent="space-between" flexWrap="wrap">
          <Logo withLink />
          {this.renderRightContent()}
          {this.renderBurgerButton()}
        </Flex>
      </Flex>
    );
  };
}

export default withRouter(Navbar);
