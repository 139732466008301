import React, { PureComponent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { logoutUser } from '../../store/actions/user';
import { deleteClientCookie, getToken } from '../../utils/token';
import ActionBuilder from '../../store/actions';
import { AuthApi } from '../../api/api';

type Props = {
  logoutUser: () => void;
  getUser: (authToken: string) => void;
  location: any;
};

class ConfirmUserContainer extends PureComponent<Props> {
  componentDidMount = async () => {
    const { logoutUser, getUser, location } = this.props;
    deleteClientCookie();
    logoutUser();
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code) {
      await AuthApi.clientConfirmUser(code);
      const authToken = getToken();
      if (authToken) {
        await getUser(authToken);
      }
    }
  };

  render = () => <Redirect to="/" />;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  getUser: async (authToken: string) => dispatch(await ActionBuilder.getUser(authToken)),
});

export default connect(null, mapDispatchToProps)(ConfirmUserContainer);
