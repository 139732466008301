import { Dispatch } from 'redux';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';
import { Text, Flex, Link } from 'rebass';
import { History } from 'history';
import * as _ from 'lodash';

import ActionBuilder from '../../store/actions';
import { AppState, Flow, InitialActionParams } from '../../types/common';
import FlowComponent from '../../components/common/FlowComponent';
import theme from '../../theme';
import { RouteParser, RouteFormatter } from '../../utils/routes';
import { generateQuery } from '../../utils/flows';

type Params = {
  appName: string;
  applicationId: string;
  appFlowName: string;
  appFlowId: string;
  versionFlowId: string;
};

type InitProps = {
  location: any;
};

type Props = InitProps & {
  dispatch: Dispatch;
  flows?: Flow[];
  history: History;
  match: match<Params>;
};

class EmbedContainer extends PureComponent<Props> {
  static initialAction = async ({ url }: InitialActionParams) => {
    const defaultResult = Promise.resolve();
    if (!url) {
      return defaultResult;
    }
    const { versionFlowId } = RouteParser.embedFlowRoute(url);
    return ActionBuilder.getFlow(versionFlowId);
  };

  loading = false;

  componentDidMount = () => {
    this.checkForNotFound();
    // track rendering of embed
    this.fetchResources();
  };

  componentDidUpdate = (prevProps: Props) => {
    this.checkForNotFound();
    if (prevProps.location !== this.props.location) {
      this.fetchResources();
    }
  };

  checkForNotFound = () => {
    const { flows, history } = this.props;
    if (_.isEqual(flows, [])) {
      history.push(RouteFormatter.notFoundRoute());
    }
  };

  fetchResources = async () => {
    const {
      dispatch,
      location: { pathname },
    } = this.props;
    const action = await EmbedContainer.initialAction({ url: pathname });
    if (action) {
      dispatch(action);
    }
  };

  renderFlowHeader = ({
    application: { name: applicationName },
    appFlow: { displayName },
    version: { semVer },
  }: Flow) => (
    <Flex pl={[1, 2, 3]} pb={[1, 2, 3]} flexDirection="column" sx={{ fontWeight: 500 }}>
      <Flex mb={[0, 1, 2]}>
        <Text>{displayName}</Text>
        <Text mx={1} sx={{ color: theme.colors.smokeLighten20 }}>
          on
        </Text>
        <Text>{applicationName}</Text>
      </Flex>
      <Flex alignItems="center">
        <Text>{`Version | ${semVer}`}</Text>
      </Flex>
    </Flex>
  );

  renderFooter = ({
    application: { name: applicationName, id: applicationId },
    appFlow: { name: appFlowName, id: appFlowId },
    id: versionFlowId,
  }: Flow) => (
    <Flex
      width={1}
      mt="auto"
      py={1}
      px={[1, 2, 3]}
      justifyContent="space-between"
      alignItems="center"
      bg={theme.colors.smokeLighten90}
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.colors.smokeLighten60,
      }}
    >
      <Flex alignItems="center">
        <Text sx={{ ...theme.styles.p }}>Hosted with</Text>
        <Text pl={1} sx={{ color: theme.colors.redDarken20 }}>
          ❤
        </Text>
        <Text pl={1} sx={{ ...theme.styles.p }}>
          by
        </Text>
        <Link
          href={'/'}
          pl={1}
          pt={[1, 2]}
          sx={{ fontWeight: 'bold', color: theme.colors.primary, ':hover': { color: theme.colors.primaryDarken10 } }}
          target="_blank"
        >
          UX-Archive
        </Link>
      </Flex>
      <Link
        href={RouteFormatter.versionFlowRoute(applicationName, applicationId, appFlowName, appFlowId, versionFlowId)}
        target="_blank"
        pt={[1, 2]}
        sx={{ fontWeight: 'bold', color: theme.colors.primary, ':hover': { color: theme.colors.primaryDarken10 } }}
      >
        View original Flow
      </Link>
    </Flex>
  );

  render = () => {
    const { flows } = this.props;
    if (!flows) {
      return null;
    }
    const flow = flows[0];
    const { id: versionFlowId } = flow;
    return (
      <Flex width={1} height="100%" minWidth={330} key={versionFlowId} flexDirection="column" fontSize={[1, 2]}>
        {this.renderFlowHeader(flow)}
        <Flex width={1} pl={[0, 1, 2]} height="100%" alignItems="center">
          <FlowComponent flow={flow} responsive />
        </Flex>
        {this.renderFooter(flow)}
      </Flex>
    );
  };
}

const mapStateToProps = (state: AppState, props: InitProps) => {
  const {
    location: { pathname },
  } = props;
  // Here we don't extract appFlowId and versionFlowId from params because this also runs server side
  const { versionFlowId } = RouteParser.embedFlowRoute(pathname);
  const request = generateQuery({ versionFlowId });
  const flows = state.flowRequests[request];
  return {
    flows: flows ? flows.filter(({ id }) => id === versionFlowId) : flows,
  };
};

export default connect(mapStateToProps)(EmbedContainer);
