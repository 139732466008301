import React, { PureComponent, Fragment, ReactNode } from 'react';
import { Box } from 'rebass';
import { Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import Navbar from '../../components/client/Navbar';
import ApplicationContainer from './ApplicationContainer';
import HomeContainer from './HomeContainer';
import NotFound404 from '../../components/common/NotFound404';
import Footer from '../../components/client/Footer';
import FlowContainer from './FlowContainer';
import AppFlowNameContainer from './AppFlowNameContainer';
import { AppState, App, Flow, AuthPage, User, InitialActionParams } from '../../types/common';
import ActionBuilder from '../../store/actions';
import copy from '../../utils/copy';
import EmbedContainer from './EmbedContainer';
import notifActionBuilder from '../../store/actions/notif';
import { ArchiveApi } from '../../api/api';
import EmbedModal from '../../components/client/EmbedModal';
import FlashNotification from '../../components/common/FlashNotification';
import Auth from '../../components/client/Auth';
import { getToken } from '../../utils/token';
import ConfirmUserContainer from './ConfirmUserContainer';
import { logoutUser } from '../../store/actions/user';
import ResetContainer from './ResetContainer';

type Props = {
  appFlowNames: string[];
  apps: App[];
  dispatch: Dispatch;
  errorMessage: string;
  getUser: (authToken: string) => void;
  logoutUser: () => void;
  successMessage: string;
  updateErrorMessage: (message: string) => void;
  updateSuccessMessage: (message: string) => void;
  user: User | null;
};

type OwnState = {
  authModalPage: AuthPage;
  blockModalPage: boolean;
  downloadModalFlow: Flow | undefined;
  embedLink: string;
  userEmail: string;
};

class LayoutContainer extends PureComponent<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      authModalPage: undefined,
      blockModalPage: false,
      downloadModalFlow: undefined,
      embedLink: '',
      userEmail: '',
    };
  }

  formRef?: HTMLFormElement;
  timeoutId: any;

  static initialAction = async ({ authToken }: InitialActionParams) =>
    await Promise.all([ActionBuilder.getAppFlowNames(), ActionBuilder.getApps(), ActionBuilder.getUser(authToken)]);

  componentDidMount = async () => {
    const { dispatch, appFlowNames, user, getUser } = this.props;
    if (!appFlowNames || appFlowNames.length === 0) {
      const actions = await LayoutContainer.initialAction({ authToken: getToken() });
      actions.map(dispatch);
    }
    if (!user && !window.location.pathname.includes('embed')) {
      this.timeoutId = setTimeout(() => {
        if (!this.state.authModalPage) {
          this.navigateTo('signup');
        }
      }, 60000);
      const authToken = getToken();
      if (authToken) {
        await getUser(authToken);
      }
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.timeoutId && !prevProps.user && this.props.user) {
      this.timeoutId = clearTimeout(this.timeoutId);
    }
  };

  setFormRef = (ref: HTMLFormElement) => {
    if (ref) {
      this.formRef = ref;
    }
  };

  handleDownload = async (flow: Flow) => {
    const { user } = this.props;
    if (!user) {
      this.navigateTo('signup');
    } else {
      await ArchiveApi.downloadAssets({ email: user.email, flowId: flow.id, type: 'flowAssets' });
      this.props.updateSuccessMessage('Assets successfully sent to your inbox!');
    }
  };

  handleCopy = (text: string) => {
    copy(text);
    this.props.updateSuccessMessage('Successfully copied to your clipboard');
  };

  onSuccessFlashNotifClose = () => this.props.updateSuccessMessage('');

  onErrorFlashNotifClose = () => this.props.updateErrorMessage('');

  onUserEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ userEmail: e.target.value });

  navigateTo = (page?: AuthPage, blockModalPage = false) => this.setState({ authModalPage: page, blockModalPage });

  toggleEmbedModal = (link?: string) => {
    this.setState({ embedLink: link || '' });
  };

  renderComponent = (Component: ReactNode) => {
    const { appFlowNames, apps, user, logoutUser } = this.props;
    return (
      <Fragment>
        <Navbar apps={apps} navigateTo={this.navigateTo} user={user} logoutUser={logoutUser} />
        {Component}
        <Footer appFlowNames={appFlowNames} />
      </Fragment>
    );
  };

  render = () => {
    const {
      apps,
      appFlowNames,
      updateErrorMessage,
      updateSuccessMessage,
      successMessage,
      errorMessage,
      getUser,
      user,
    } = this.props;
    const shareMethods = {
      onDownload: this.handleDownload,
      embed: this.toggleEmbedModal,
    };
    const notifMethods = {
      updateErrorMessage,
      updateSuccessMessage,
    };
    const { embedLink, authModalPage, blockModalPage } = this.state;
    const flowContainerProps = {
      ...shareMethods,
      ...notifMethods,
      user,
      navigateTo: this.navigateTo,
    };
    return (
      <Fragment>
        <Box width={1} height="100%" bg="white">
          <FlashNotification text={successMessage} onClose={this.onSuccessFlashNotifClose} type="success" />
          <FlashNotification text={errorMessage} onClose={this.onErrorFlashNotifClose} type="error" />
          <EmbedModal embedLink={embedLink} handleCopy={this.handleCopy} onClose={this.toggleEmbedModal} />
          <Auth navigateTo={this.navigateTo} page={authModalPage} block={blockModalPage} getUser={getUser} />
          <Switch>
            <Route
              exact
              path="/reset-password/:code"
              render={(routerProps) => (
                <Fragment>
                  <Navbar apps={apps} navigateTo={this.navigateTo} user={user} logoutUser={logoutUser} />
                  <ResetContainer {...routerProps} navigateTo={this.navigateTo} getUser={getUser} />
                </Fragment>
              )}
            />
            <Route
              exact
              path="/app/:appName/:applicationId/:semVer/:versionId/:pageNumber?"
              render={(routerProps) =>
                this.renderComponent(<ApplicationContainer {...routerProps} {...flowContainerProps} />)
              }
            />
            <Route
              exact
              path="/appFlowName/:appFlowName/:pageNumber?"
              render={(routerProps) =>
                this.renderComponent(<AppFlowNameContainer {...routerProps} user={user} navigateTo={this.navigateTo} />)
              }
            />
            <Route
              exact
              path="/flow/:appName/:applicationId/:appFlowName/:appFlowId/:versionFlowId?/:pageNumber?"
              render={(routerProps) => this.renderComponent(<FlowContainer {...routerProps} {...flowContainerProps} />)}
            />
            <Route
              exact
              path="/embed/:appName/:applicationId/:appFlowName/:appFlowId/:versionFlowId"
              render={(routerProps) => <EmbedContainer {...routerProps} />}
            />
            <Route exact path="/confirm" component={ConfirmUserContainer} />
            <Route
              exact
              path="/:pageNumber(\d+)?"
              render={(routerProps) =>
                this.renderComponent(
                  <HomeContainer
                    {...routerProps}
                    appFlowNames={appFlowNames}
                    user={user}
                    navigateTo={this.navigateTo}
                  />,
                )
              }
            />
            <Route render={(routerProps) => this.renderComponent(<NotFound404 {...routerProps} />)} />
          </Switch>
        </Box>
        <a
          href="https://www.producthunt.com/posts/waldo-sessions-1?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-waldo&#0045;sessions&#0045;1"
          target="_blank"
          rel="noreferrer"
          style={{ position: 'fixed', bottom: '20px', left: '20px', zIndex: 100 }}
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=382508&theme=light"
            alt="Waldo&#0032;Sessions - Run&#0032;inspect&#0032;and&#0032;share&#0032;your&#0032;mobile&#0032;app&#0032;right&#0032;from&#0032;the&#0032;browser | Product Hunt"
            style={{ width: 250, height: 54 }}
          />
        </a>
      </Fragment>
    );
  };
}

const mapStateToProps = (state: AppState) => ({
  appFlowNames: state.appFlowNames,
  apps: state.apps,
  errorMessage: state.errorMessage,
  successMessage: state.successMessage,
  user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
  updateErrorMessage: (message: string) => notifActionBuilder.updateErrorMessage(message, dispatch),
  updateSuccessMessage: (message: string) => notifActionBuilder.updateSuccessMessage(message, dispatch),
  getUser: async (authToken: string) => dispatch(await ActionBuilder.getUser(authToken)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
