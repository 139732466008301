import axios from 'axios';

import { API_URL, AUTH_API_URL } from '../utils/constants';
import {
  PatchFlowRequest,
  AppVersion,
  GetFlowsParams,
  GetAppsParams,
  PostEmailParams,
  CreateUserPayload,
  ApplicationState,
  PatchAppFlow,
} from '../types/common';

const TOKEN_PREFIX = 'Token';
const ADMIN_TOKEN_PREFIX = 'AdminToken';

const buildOptions = (adminAuthToken?: string, prefix = TOKEN_PREFIX) => ({
  headers: { Authorization: `${prefix} ${adminAuthToken}` },
});

const buildAdminOptions = (authToken?: string) => buildOptions(authToken, ADMIN_TOKEN_PREFIX);

export class AuthApi {
  static adminLogin = async (email: string, password: string) => {
    const adminAuthToken = btoa(`${email}:${password}`);
    await axios.get('/auth/admin/login', buildOptions(adminAuthToken, 'Basic'));
  };

  static getAdminToken = async (credentials: string) => {
    const {
      data: { token },
    } = await axios.post(`${AUTH_API_URL}/authenticationTokens`, {}, { headers: { Authorization: credentials } });
    return token;
  };

  static checkAdminToken = async (adminAuthToken: string) => {
    const url = `${API_URL}/admin/flows`;
    await axios.get(url, buildAdminOptions(adminAuthToken));
  };

  static login = async (email: string, password: string) => {
    const authToken = btoa(`${email}:${password}`);
    await axios.get('/auth/client/login', buildOptions(authToken, 'Basic'));
  };

  static getToken = async (credentials: string) => {
    const {
      data: { token },
    } = await axios.post(`${API_URL}/authenticationTokens`, {}, { headers: { Authorization: credentials } });
    return token;
  };

  static createUser = async (createUserPayload: CreateUserPayload) => {
    const {
      data: { token },
    } = await axios.post(`${API_URL}/users`, { ...createUserPayload, name: 'we_dont_care' });
    return token;
  };

  static clientConfirmUser = async (code: string) => {
    await axios.get('/auth/client/verify', { params: { code } });
  };

  static confirmUser = async (code: string) => {
    const {
      data: { token },
    } = await axios.get(`${API_URL}/users/verify`, { params: { code } });
    return token;
  };

  static generateResetCode = async (email: string) => {
    await axios.post(`${API_URL}/users/passwordCodes`, { email });
  };

  static clientUpdatePassword = async (code: string, password: string) => {
    await axios.patch('/auth/client/users/passwords', { code, password });
  };

  static updatePassword = async (code: string, password: string) => {
    const {
      data: { token },
    } = await axios.patch(`${API_URL}/users/passwords`, { code, password });
    return token;
  };

  static getUser = async (authToken?: string) => {
    const { data: user } = await axios.get(`${API_URL}/users/me`, buildOptions(authToken));
    return user;
  };
}

export class ArchiveApi {
  static getAdminFlows = async (adminAuthToken?: string) => {
    const url = `${API_URL}/admin/flows`;
    const { data } = await axios.get(url, buildAdminOptions(adminAuthToken));
    return data;
  };

  static getInfos = async () => {
    const url = `${API_URL}/info`;
    const { data } = await axios.get(url);
    return data;
  };

  static getFlows = async (params: GetFlowsParams = {}) => {
    const url = `${API_URL}/flows`;
    const { data } = await axios.get(url, { params });
    return data;
  };

  static getFlow = async (flowId: string) => {
    const url = `${API_URL}/flows/${flowId}`;
    const { data } = await axios.get(url);
    return data;
  };

  static getAppFlowNames = async () => {
    const url = `${API_URL}/appFlows/names`;
    const { data } = await axios.get(url);
    return data;
  };

  static getAppVersions = async (applicationId: string) => {
    const url = `${API_URL}/applications/${applicationId}/versions`;
    const { data: rawAppVersions } = await axios.get(url);
    // Archive API detects new versions on App Store
    // We want to filter those that have not been uploaded to webapp yet
    return rawAppVersions.filter((item: AppVersion) => !!item.status);
  };

  static updateFlow = async (patchFlowRequest: PatchFlowRequest, adminAuthToken?: string) => {
    const { id, interactions, status } = patchFlowRequest;
    const url = `${API_URL}/admin/flows/${id}`;
    const { data } = await axios.patch(url, { interactions, status }, buildOptions(adminAuthToken, ADMIN_TOKEN_PREFIX));
    return data;
  };

  static getAdminApps = async (adminAuthToken?: string) => {
    const url = `${API_URL}/admin/applications`;
    const { data } = await axios.get(url, buildAdminOptions(adminAuthToken));
    return data;
  };

  static getAdminApplication = async (applicationId: string, adminAuthToken?: string) => {
    const url = `${API_URL}/admin/applications/${applicationId}`;
    const { data } = await axios.get(url, buildAdminOptions(adminAuthToken));
    return data;
  };

  static getApps = async (params: GetAppsParams = {}) => {
    const url = `${API_URL}/applications`;
    const { data } = await axios.get(url, { params });
    return data;
  };

  static addApplication = async (appStoreUrl: string, adminAuthToken?: string) => {
    const url = `${API_URL}/admin/applications`;
    const { data } = await axios.put(url, { appStoreUrl }, buildAdminOptions(adminAuthToken));
    return data;
  };

  static patchApplication = async (applicationId: string, state: ApplicationState, adminAuthToken?: string) => {
    const url = `${API_URL}/admin/applications/${applicationId}`;
    const { data } = await axios.patch(url, { state }, buildAdminOptions(adminAuthToken));
    return data;
  };

  static patchAppFlow = async (appFlowId: string, update: PatchAppFlow, adminAuthToken?: string) => {
    const url = `${API_URL}/admin/appFlows/${appFlowId}`;
    const { data } = await axios.patch(url, update, buildAdminOptions(adminAuthToken));
    return data;
  };

  static downloadAssets = async (body: PostEmailParams) => {
    const url = `${API_URL}/emails`;
    const {
      data: { success },
    } = await axios.post(url, body);
    if (!success) {
      throw new Error('could not download assets');
    }
  };

  static subscribeUser = async (body: PostEmailParams) => {
    const url = `${API_URL}/emails`;
    const {
      data: { success },
    } = await axios.post(url, body);
    if (!success) {
      throw new Error('could not subscribe user to newsletter');
    }
  };

  static getAdminUserName = async (adminAuthToken?: string) => {
    const url = `${AUTH_API_URL}/users/me`;
    const {
      data: { firstName, lastName },
    } = await axios.get(url, buildOptions(adminAuthToken));
    return `${firstName} ${lastName}`;
  };
}
