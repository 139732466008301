const colors = {
  text: '#333333',
  background: '#fff',
  secondary: '#006fbb',
  highlight: '#47c1bf',
  muted: '#e6e6e6',
  gray: '#dfe3e8',
  accent: '#f49342',
  darken: '#00044c',
  modes: {
    dark: {
      text: '#3e4155',
      background: '#000639',
      primary: '#9c6ade',
      secondary: '#b4e1fa',
      highlight: '#b7ecec',
      muted: '#e6e6e6',
    },
  },
  primary: '#6C75F1',
  primaryDarken10: '#4C57ED',
  primaryFade25: '#DADCFB',
  primaryFade10: '#F0F1FE',
  primaryVideo: '#EDEFFE',
  midnight: '#333333',
  smoke: '#9095A2',
  pearl: '#989DBD',
  whitePearl: '#F9FAFE',
  white: '#ffffff',
  black: '#000000',
  green: '#6FCF97',
  greenDarken20: '#3FBE74',
  greenFade20: '#E2F5EA',
  red: '#EB5757',
  redDarken20: '#E41D1D',
  yellow: '#FFC107',
  yellowLight: '#F6EAC4',
  yellowDarken20: '#D19D00',
  midnightLighten20: '#5B5B5B',
  midnightLighten40: '#848484',
  midnightLighten60: '#ADADAD',
  midnightLighten80: '#D6D6D6',
  midnightDarken20: '#282828',
  midnightDarken40: '#1E1E1E',
  midnightDarken60: '#141414',
  midnightDarken80: '#0A0A0A',
  smokeLighten20: '#A6AAB4',
  smokeLighten40: '#BCBFC7',
  smokeLighten60: '#D2D4D9',
  smokeLighten80: '#E8E9EC',
  smokeLighten90: '#F3F4F5',
  smokeDarken20: '#6F7585',
  smokeDarken40: '#535863',
  smokeDarken60: '#373A42',
  smokeDarken80: '#1B1D21',
};

const variants = {
  link: {
    textDecoration: 'none',
  },
  iconShadow: {
    boxShadow:
      'rgba(0, 0, 0, 0.1) 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 1px 3px -2px, rgba(0, 0, 0, 0.15) 0px 5px 15px -5px',
  },
};

const COMMON_BUTTON_STYLE = {
  borderRadius: 6,
  transition: 'all .3s ease',
  fontFamily: 'body',
  fontWeight: 500,
  fontSize: 1,
  lineHeight: '14px',
  padding: ['2px 15px 4px', '10px 24px 12px'],
  height: 42,
  ':focus': {
    outline: 'none',
  },
  ':active': {
    opacity: 0.7,
  },
};

const heading = {
  color: 'midnight',
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
};

const text = 'walDIN, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif';

export const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      ...heading,
      fontSize: 5,
    },
    h2: {
      ...heading,
      fontSize: 4,
    },
    h3: {
      ...heading,
      fontSize: 3,
    },
    h4: {
      ...heading,
      fontSize: 2,
    },
    h5: {
      ...heading,
      fontSize: 1,
    },
    h6: {
      ...heading,
      fontSize: 0,
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
  colors,
  variants,
  fonts: {
    body: text,
    heading: text,
    moonospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.75,
    heading: 1.25,
  },
  forms: {
    input: {
      pl: 3,
      borderRadius: 8,
      border: 'none',
      color: colors.midnight,
      boxShadow: `0 0 0 1px rgba(152, 157, 189, 0.3)`,
      fontSize: 2,
      ':focus': {
        outline: 'none',
        boxShadow: `0 0 0 2px ${colors.primary}`,
      },
    },
  },
  buttons: {
    primary: {
      ...COMMON_BUTTON_STYLE,
      bg: colors.primary,
      boxShadow: '0px 1px 4px rgba(76, 87, 237, 0.24)',
      ':hover': {
        cursor: 'pointer',
        bg: colors.primaryDarken10,
      },
    },
    primaryDisabled: {
      ...COMMON_BUTTON_STYLE,
      bg: colors.primaryFade25,
      cursor: 'default',
      ':hover': {
        cursor: 'default',
        bg: colors.primaryFade25,
      },
    },
    secondary: {
      ...COMMON_BUTTON_STYLE,
      bg: 'white',
      color: colors.primary,
      boxShadow: '0px 1px 4px rgba(51, 51, 51, 0.1)',
      ':hover': {
        cursor: 'pointer',
        color: colors.primaryDarken10,
        boxShadow: '0px 1px 4px rgba(51, 51, 51, 0.2)',
      },
    },
    secondaryDisabled: {
      ...COMMON_BUTTON_STYLE,
      bg: 'white',
      color: colors.primary,
      boxShadow: '0px 1px 4px rgba(51, 51, 51, 0.1)',
      opacity: 0.4,
      cursor: 'default',
      ':hover': {
        cursor: 'default',
        color: colors.primaryDarken10,
        boxShadow: '0px 1px 4px rgba(51, 51, 51, 0.2)',
      },
    },
    danger: {
      ...COMMON_BUTTON_STYLE,
      bg: colors.red,
      color: 'white',
      boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
      ':hover': {
        cursor: 'pointer',
        bg: colors.redDarken20,
        boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
      },
    },
    outline: {
      ...COMMON_BUTTON_STYLE,
      borderRadius: 6,
      height: 40,
      fontSize: 1,
      bg: 'transparent',
      color: colors.primary,
      backgroundSize: 'cover',
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: colors.primary,
      ':hover': {
        cursor: 'pointer',
      },
    },
    text: {
      ...COMMON_BUTTON_STYLE,
      border: 'none',
      background: 'none',
      color: colors.primary,
      fontSize: 2,
      fontWeight: 500,
      boxShadow: 'none',
      ':hover': { cursor: 'pointer', background: 'none', boxShadow: 'none', textDecoration: 'underline' },
    },
  },
};

export default theme;
