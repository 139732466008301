import React, { PureComponent } from 'react';
import { Flex, Text, Button } from 'rebass';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import theme from '../../theme';
import ActionBuilder from '../../store/actions';
import { getAdminToken, deleteAdminCookie } from '../../utils/token';
import { AppState, InitialActionParams } from '../../types/common';

type Props = {
  adminUserName: string;
  dispatch: Dispatch;
};

class AdminNavbarContainer extends PureComponent<Props> {
  static initialAction = ({ adminAuthToken }: InitialActionParams) => ActionBuilder.getAdminUserName(adminAuthToken);

  componentDidMount = async () => {
    const { adminUserName, dispatch } = this.props;
    if (!adminUserName) {
      const action = await AdminNavbarContainer.initialAction({ adminAuthToken: getAdminToken() });
      dispatch(action);
    }
  };

  logout = () => {
    deleteAdminCookie();
    window.location.href = '/';
  };

  render = () => (
    <Flex
      width={1}
      minHeight={70}
      px={4}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.gray,
        borderBottomStyle: 'solid',
      }}
    >
      <Text as="h3">Admin</Text>
      <Flex width={300} justifyContent="space-between" alignItems="center">
        <Text width={200} sx={{ textAlign: 'right' }}>
          {this.props.adminUserName}
        </Text>
        <Button onClick={this.logout}>Logout</Button>
      </Flex>
    </Flex>
  );
}

const mapStateToProps = (state: AppState) => ({
  adminUserName: state.adminUserName,
});

export default connect(mapStateToProps)(AdminNavbarContainer);
