import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import cookie from 'cookie';

import App from '../shared/App';
import configureStore from '../shared/store';
import { ADMIN_COOKIE_NAME } from '../shared/utils/constants';
import { identify } from '../shared/utils/track';

import './index.css';
import './fonts.css';

const store = configureStore(window.__initialData__);
const adminloggedIn = !!cookie.parse(document.cookie)[ADMIN_COOKIE_NAME];

// Re-identify on the server side, in case the user just logged-in
const { user } = store.getState();
if (user) {
  identify(user.id);
}

hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <App isAdmin={window.location.hostname.startsWith('admin')} adminloggedIn={adminloggedIn} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
