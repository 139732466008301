import { Dispatch } from 'redux';
import { Box, Flex, Text } from 'rebass';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';
import { History } from 'history';
import { sortBy, uniqBy } from 'lodash';

import ActionBuilder from '../../store/actions';
import { AppState, Flow, InitialActionParams } from '../../types/common';
import CustomDropdown from '../../components/common/Dropdown';
import { getAdminToken } from '../../utils/token';
import theme from '../../theme';
import AdminFlowContainer from './AdminFlowContainer';
import PageManager from '../../components/client/PageManager';
import { ITEMS_PER_PAGE } from '../../utils/constants';

type Params = {
  pageNumber?: string;
};

type Props = {
  adminFlows: Flow[];
  dispatch: Dispatch;
  history: History;
  match: match<Params>;
};

type State = {
  selectedApp: string;
};

const ALL_APPS = 'All';

class AdminToDoContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedApp: ALL_APPS,
    };
  }
  static initialAction = ({ adminAuthToken }: InitialActionParams) => ActionBuilder.getAdminFlows(adminAuthToken);

  componentDidMount = async () => {
    const { dispatch } = this.props;
    const action = await AdminToDoContainer.initialAction({ adminAuthToken: getAdminToken() });
    dispatch(action);
  };

  getPageNumber = () =>
    this.state.selectedApp === ALL_APPS ? parseInt(this.props.match.params.pageNumber || '1', 10) : 1;

  onPageNumberChange = (pageNumber: number) => this.props.history.push(`/${pageNumber}`);

  onAppChange = (appName: string) => this.setState({ selectedApp: appName });

  renderAppsDropDown = () => {
    const allApps = [ALL_APPS];
    const dedupedApps = sortBy(
      uniqBy(this.props.adminFlows, (flow) => flow.application.id),
      (flow) => flow.application.name,
    );
    allApps.push(...dedupedApps.map((flow) => flow.application.name));
    return (
      <CustomDropdown
        styleProps={{ px: 3, py: 3 }}
        values={allApps}
        onClick={(appName: string) => this.onAppChange(appName)}
      >
        <Text as="p" style={{ width: theme.space[theme.space.length - 3] }}>
          {this.state.selectedApp}
        </Text>
      </CustomDropdown>
    );
  };

  renderFlow = (flow: Flow) => <AdminFlowContainer key={flow.id} flow={flow} />;

  getFlows = (flows: Flow[]) => {
    const { selectedApp } = this.state;
    return selectedApp === ALL_APPS ? flows : flows.filter((flow) => flow.application.name === selectedApp);
  };

  getItemsPerPage = () => (this.state.selectedApp === ALL_APPS ? ITEMS_PER_PAGE : 100 * ITEMS_PER_PAGE);
  renderFlows = (flows: Flow[]) => {
    const pageNumber = this.getPageNumber();
    const itemsPerPage = this.getItemsPerPage();
    const pageFlows = this.getFlows(flows).slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage);
    return (
      <Box width={1} mb={5}>
        {pageFlows.map(this.renderFlow)}
      </Box>
    );
  };

  renderPageManager = () => (
    <Box width={1} mb={5}>
      <PageManager
        pageNumber={this.getPageNumber()}
        onPageNumberChange={this.onPageNumberChange}
        itemsLength={this.props.adminFlows.length}
      />
    </Box>
  );

  render = () => {
    const { adminFlows } = this.props;
    return (
      <Flex width={1} p={4} flexDirection="column">
        <Text as="h2" mb={3}>
          To do
        </Text>
        {this.renderAppsDropDown()}
        {this.renderFlows(adminFlows)}
        {this.state.selectedApp === ALL_APPS && this.renderPageManager()}
      </Flex>
    );
  };
}

const mapStateToProps = (state: AppState) => ({
  adminFlows: state.adminFlows.filter(({ status }) => status === 'needs_validation'),
});

export default connect(mapStateToProps)(AdminToDoContainer);
