import React from 'react';
import { Text, Button, Flex } from 'rebass';

import theme from '../../theme';

type Props = {
  text: string;
  onClose: () => void;
  type: 'success' | 'error';
};

const FlashNotification = ({ text, onClose, type }: Props) =>
  text ? (
    <Flex width={1} sx={{ position: 'fixed', top: 40, textAlign: 'center', justifyContent: 'center', zIndex: 2000 }}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px={3}
        py={1}
        sx={{ bg: type === 'success' ? theme.colors.green : theme.colors.red, color: 'white', borderRadius: 8 }}
      >
        <Text as="span">{text}</Text>
        <Button
          className="icon-cross-icon"
          ml={2}
          p={0}
          pl={4}
          onClick={onClose}
          sx={{
            fontSize: 3,
            color: 'white',
            bg: 'transparent',
            border: 'none',
            boxShadow: 'none',
            ':hover': { bg: 'transparent' },
          }}
        />
      </Flex>
    </Flex>
  ) : null;

export default FlashNotification;
