import {
  GET_ADMIN_USERNAME_SUCCESS,
  GET_ADMIN_USERNAME_ERROR,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  LOGOUT_USER,
} from './actionTypes';

export const receivedAdminUserName = (payload: any) => ({
  type: GET_ADMIN_USERNAME_SUCCESS,
  payload,
});

export const adminUserNameError = (payload: any) => ({
  type: GET_ADMIN_USERNAME_ERROR,
  payload,
});

export const receivedUser = (payload: any) => ({
  type: GET_USER_SUCCESS,
  payload,
});

export const userError = (payload: any) => ({
  type: GET_USER_ERROR,
  payload,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
});
