import React from 'react';
import { Box, Flex } from 'rebass';

import theme from '../../theme';

type Props = {
  color?: string;
  height?: string;
  mx?: number | string;
  mt?: number | string;
  size?: number;
  width?: number;
};

const Loader = ({ width = 1, height = '100%', mx = 0, mt = 0, color = theme.colors.primary, size = 50 }: Props) => (
  <Flex width={width} height={height} mx={mx} mt={mt} justifyContent="center" alignItems="center">
    <Box
      width={size}
      height={size}
      sx={{
        background: 'transparent',
        borderTop: `4px solid ${color}`,
        borderRight: '4px solid transparent',
        borderRadius: '50%',
        animation: '1s spin linear infinite',
      }}
    />
  </Flex>
);

export default Loader;
