import { Dispatch } from 'redux';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Flex, Text, Button } from 'rebass';
import { Switch } from '@rebass/forms';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import { Modal, Badge, Space, Table, Button as AntdButton } from 'antd';

import ActionBuilder from '../../store/actions';
import { AppState, App, InitialActionParams, ApplicationState } from '../../types/common';
import CustomDropdown from '../../components/common/Dropdown';
import { getAdminToken } from '../../utils/token';
import { Input } from '@rebass/forms';
import theme from '../../theme';
import { RouteFormatter } from '../../utils/routes';
import { pick } from 'lodash';

const DISPLAYED_STATE_TO_BACKEND_STATE = {
  published: 'ready',
  unpublished: 'pending',
  archived: 'invalid',
};

type DisplayedFlowStatus = (typeof DISPLAYED_STATE_TO_BACKEND_STATE)[keyof typeof DISPLAYED_STATE_TO_BACKEND_STATE];

type ApplicationRecord = {
  id: string;
  name: string;
  availableApiVersion: boolean;
  numFlowsValid: number;
  numFlowsNeedValidation: number;
  latestVersion: string;
  category: string;
  state: DisplayedFlowStatus;
};

type Filter = {
  name?: string;
  state?: DisplayedFlowStatus[];
  availableApiVersion?: boolean;
};

type Order = {
  columnKey: string;
  order: 'descend' | 'ascend';
};

type ColumnValue = string | number | boolean;

type Props = {
  addApplication: (appStoreUrl: string) => void;
  adminApps: App[];
  dispatch: Dispatch;
  patchApplication: (applicationId: string, state: ApplicationState) => void;
};

type OwnState = {
  appUrl: string;
  modalVisible: boolean;
  hideArchivedApps: boolean;
  filteredInfo: Filter;
  sortedInfo: Order | null;
};

class AdminAppsContainer extends PureComponent<Props, OwnState> {
  state = {
    appUrl: '',
    modalVisible: false,
    hideArchivedApps: false,
    filteredInfo: {},
    sortedInfo: null,
  };

  static initialAction = ({ adminAuthToken }: InitialActionParams) => ActionBuilder.getAdminApps(adminAuthToken);

  componentDidMount = async () => {
    const { dispatch } = this.props;
    const action = await AdminAppsContainer.initialAction({ adminAuthToken: getAdminToken() });
    dispatch(action);
  };

  toggleAddAppModal = () => this.setState({ modalVisible: !this.state.modalVisible });

  onStatusChange = async (appId: string, status: DisplayedFlowStatus) => {
    const backStatus = DISPLAYED_STATE_TO_BACKEND_STATE[status];
    await this.props.patchApplication(appId, backStatus);
  };

  onAppUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ appUrl: e.target.value });

  onCancel = () =>
    this.setState({
      appUrl: '',
      modalVisible: false,
    });

  onSubmit = () => {
    this.props.addApplication(this.state.appUrl);
    this.toggleAddAppModal();
  };

  renderColumnName = (name: string) => (
    <Text key={name} as="h4" width={1}>
      {name}
    </Text>
  );

  renderColumnNames = () => (
    <Flex width={1}>
      {['Name', 'Latest Version', 'Category', 'Build Available', 'Num valid flows', 'Status'].map((name) =>
        this.renderColumnName(name),
      )}
    </Flex>
  );

  getApplicationFromId = (appId: string) => {
    const appsById = _.keyBy(this.props.adminApps);
    return appsById[appId];
  };

  renderAppStateDropdown = (applicationId: string, state: DisplayedFlowStatus) => {
    const stateKeys = Object.keys(DISPLAYED_STATE_TO_BACKEND_STATE);
    return (
      <CustomDropdown
        values={stateKeys}
        onClick={(state: ApplicationState) => this.onStatusChange(applicationId, state)}
      >
        <Text as="p" style={{ width: 65 }}>
          {state}
        </Text>
      </CustomDropdown>
    );
  };

  renderBadge = (available: boolean) => {
    const status = available ? 'success' : 'error';
    return (
      <Flex width={1} alignItems="center" justifyContent="center">
        <Badge status={status} />
      </Flex>
    );
  };

  renderAddAppModal = () => (
    <Modal open={this.state.modalVisible} onCancel={this.toggleAddAppModal} footer={null}>
      <Flex flexDirection="column" width={1}>
        <Text as="h2" mb={5} sx={{ ...theme.styles.h2 }}>
          Add an app
        </Text>
        <Flex width={1} mb={5} alignItems="center">
          <Text as="h4" sx={{ ...theme.styles.h4 }} width={150}>
            App Store URL:
          </Text>
          <Input type="text" onChange={this.onAppUrlChange} />
        </Flex>
        <Flex width={1} justifyContent="flex-end">
          <Button variant="secondary" onClick={this.onCancel}>
            Cancel
          </Button>
          <Button ml={3} onClick={this.onSubmit}>
            Add app
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );

  toggleArchivedApps = () => {
    this.setState({
      hideArchivedApps: !this.state.hideArchivedApps,
      filteredInfo: {
        ...this.state.filteredInfo,
        state: this.state.hideArchivedApps ? [] : ['published', 'unpublished'],
      },
    });
  };

  handleChange = (pagination: any, filters: Filter, sorter: any) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: {} });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: {},
      sortedInfo: null,
    });
  };

  hideArchived = () =>
    this.setState({
      filteredInfo: {
        ...this.state.filteredInfo,
        state: ['published', 'unpublished'],
      },
    });

  renderTable = () => {
    const { filteredInfo, sortedInfo, hideArchivedApps } = this.state;
    const appNameFilters = _.uniq(this.props.adminApps.map((app) => app.name)).map((name) => ({
      text: name,
      value: name,
    }));
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        filters: appNameFilters,
        filteredValue: _.get(filteredInfo, 'name') || null,
        onFilter: (value: ColumnValue, record: ApplicationRecord) => record.name === value,
        ellipsis: true,
        render: (name: string, record: ApplicationRecord) => (
          <Link
            to={RouteFormatter.adminApplicationRoute(record.id, 'latest')}
            style={{ textDecoration: 'none', width: '100%', color: theme.colors.primary }}
          >
            {name}
          </Link>
        ),
      },
      {
        title: 'Latest Version',
        dataIndex: 'latestVersion',
        key: 'latestVersion',
        ellipsis: true,
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        ellipsis: true,
      },
      {
        title: 'Build Available',
        dataIndex: 'availableApiVersion',
        key: 'availableApiVersion',
        filters: [
          {
            text: 'available',
            value: true,
          },
          {
            text: 'unavailable',
            value: false,
          },
        ],
        filteredValue: _.get(filteredInfo, 'availableApiVersion') || null,
        onFilter: (available: ColumnValue, record: ApplicationRecord) => record.availableApiVersion === !!available,
        render: (available: boolean) => this.renderBadge(available),
        ellipsis: true,
      },
      {
        title: 'Valid Flows',
        dataIndex: 'numFlowsValid',
        key: 'numFlowsValid',
        sorter: (left: ApplicationRecord, right: ApplicationRecord) => left.numFlowsValid - right.numFlowsValid,
        sortOrder:
          !!sortedInfo && _.get(sortedInfo, 'columnKey') === 'numFlowsValid' ? _.get(sortedInfo, 'order') : null,
      },
      {
        title: 'Need Validation Flows',
        dataIndex: 'numFlowsNeedValidation',
        key: 'numFlowsNeedValidation',
        sorter: (left: ApplicationRecord, right: ApplicationRecord) =>
          left.numFlowsNeedValidation - right.numFlowsNeedValidation,
        sortOrder:
          !!sortedInfo && _.get(sortedInfo, 'columnKey') === 'numFlowsNeedValidation'
            ? _.get(sortedInfo, 'order')
            : null,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        filters: Object.keys(DISPLAYED_STATE_TO_BACKEND_STATE).map((key) => ({
          text: key,
          value: key,
        })),
        filteredValue: _.get(filteredInfo, 'state') || null,
        onFilter: (state: ColumnValue, record: ApplicationRecord) => record.state === state,
        render: (state: DisplayedFlowStatus, record: ApplicationRecord) =>
          this.renderAppStateDropdown(record.id, state),
      },
    ];

    const formattedData: ApplicationRecord[] = this.props.adminApps.map((app) => ({
      ...pick(app, ['id', 'name', 'availableApiVersion', 'numFlowsValid', 'numFlowsNeedValidation']),
      latestVersion: app.latestVersion.version,
      category: app.latestVersion.categories.join(' - '),
      state:
        Object.keys(DISPLAYED_STATE_TO_BACKEND_STATE).find(
          (key) => DISPLAYED_STATE_TO_BACKEND_STATE[key] === app.state,
        ) || DISPLAYED_STATE_TO_BACKEND_STATE.unpublished,
    }));

    return (
      <Flex width={1} p={4} flexDirection="column">
        <Space style={{ marginBottom: 16 }}>
          <Switch checked={hideArchivedApps} onClick={this.toggleArchivedApps} sx={{ cursor: 'pointer' }} />
          <Text pl={2} fontWeight={500}>
            Hide Archived
          </Text>
          <AntdButton onClick={this.clearFilters}>Clear filters</AntdButton>
          <AntdButton onClick={this.clearAll}>Clear filters and sorters</AntdButton>
        </Space>
        <Table columns={columns} dataSource={formattedData} onChange={this.handleChange} rowKey="id" />
      </Flex>
    );
  };
  render = () => (
    <Flex width={1} p={4} flexDirection="column">
      {this.renderAddAppModal()}
      <Text as="h2">Apps</Text>
      <Button width={120} mb={3} sx={{ alignSelf: 'flex-end' }} onClick={this.toggleAddAppModal}>
        Add an app
      </Button>
      {this.renderTable()}
    </Flex>
  );
}

const mapStateToProps = (state: AppState) => ({
  adminApps: _.sortBy(state.adminApps, 'name'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addApplication: async (appStoreUrl: string) =>
    dispatch(await ActionBuilder.addApplication(appStoreUrl, getAdminToken())),
  patchApplication: async (applicationId: string, state: ApplicationState) =>
    dispatch(await ActionBuilder.patchApplication(applicationId, state, getAdminToken())),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminAppsContainer);
