import { Dispatch } from 'redux';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Box, Text, Flex, Image } from 'rebass';
import { History } from 'history';
import * as _ from 'lodash';

import ActionBuilder from '../../store/actions';
import { AppState, Flow, InitialActionParams, GetFlowsParams, AuthPage, User } from '../../types/common';
import ActionBoxContainer from './ActionBoxContainer';
import PoweredBy from '../../components/client/PoweredBy';
import FlowComponent from '../../components/common/FlowComponent';
import theme from '../../theme';
import { RouteParser, RouteFormatter, getPageNumberParams } from '../../utils/routes';
import { match, Link } from 'react-router-dom';
import { LEFT_BANNER_WIDTH, ITEMS_PER_PAGE } from '../../utils/constants';
import Badge from '../../components/client/Badge';
import PageManager from '../../components/client/PageManager';
import Loader from '../../components/common/Loader';
import { generateQuery } from '../../utils/flows';

type Params = {
  appFlowName: string;
  pageNumber?: string;
};

type InitProps = {
  location: any;
};

type Props = InitProps & {
  appFlowName: string;
  dispatch: Dispatch;
  flows: Flow[];
  getFlows: (params: GetFlowsParams) => void;
  history: History;
  navigateTo: (page?: AuthPage) => void;
  match: match<Params>;
  user: User | null;
};

class AppFlowNameContainer extends PureComponent<Props> {
  static initialAction = async ({ url }: InitialActionParams) => {
    const defaultResult = Promise.resolve();
    if (!url) {
      return defaultResult;
    }
    const { appFlowName, pageNumber } = RouteParser.appFlowRoute(url);
    if (!appFlowName || typeof appFlowName !== 'string') {
      return defaultResult;
    }
    return ActionBuilder.getFlows({
      appFlowName: decodeURI(appFlowName),
      ...getPageNumberParams(pageNumber),
    });
  };

  componentDidMount = () => {
    this.checkForNotFound();
    this.fetchResources();
  };

  componentDidUpdate = (prevProps: Props) => {
    this.checkForNotFound();
    if (prevProps.location !== this.props.location) {
      this.fetchResources();
    }
  };

  checkForNotFound = () => {
    const { flows, history } = this.props;
    if (_.isEqual(flows, [])) {
      history.push(RouteFormatter.notFoundRoute());
    }
  };

  fetchResources = async () => {
    const {
      dispatch,
      location: { pathname },
    } = this.props;
    const action = await AppFlowNameContainer.initialAction({ url: pathname });
    if (action) {
      dispatch(action);
    }
  };

  getPageNumber = () => parseInt(this.props.match.params.pageNumber || '1', 10);

  getFlowsCount = () => {
    const { flows } = this.props;
    if (!flows) {
      return 0;
    }
    return (this.getPageNumber() - 1) * ITEMS_PER_PAGE + flows.length;
  };

  onPageNumberChange = (pageNumber: number) => {
    const { appFlowName, history } = this.props;
    history.push(RouteFormatter.appFlowNameRoute(appFlowName, pageNumber));
  };

  renderFlowInfo = ({
    application: { name: applicationName, id: applicationId },
    version: { categories, iconUrl, semVer },
  }: Flow) => (
    <Flex minWidth={LEFT_BANNER_WIDTH} mb={3}>
      <Image src={iconUrl} minWidth={40} height={40} variant="iconShadow" sx={{ borderRadius: 6 }} alt="icon" />
      <Box px={2}>
        <Link
          to={RouteFormatter.applicationRoute(applicationName, applicationId, semVer)}
          style={{ textDecoration: 'none' }}
        >
          <Text mb={2} sx={{ ...theme.styles.h4 }}>
            {applicationName}
          </Text>
        </Link>
        <Badge text={categories[0]} />
      </Box>
    </Flex>
  );

  renderFlow = (flow: Flow) => {
    const {
      application: { name: appName, id: applicationId },
      appFlow: { id: appFlowId, name: appFlowName },
    } = flow;
    return (
      <Flex width={1} key={flow.id} mb={5} flexDirection={['column', 'row']}>
        {this.renderFlowInfo(flow)}
        <Box width={1}>
          <FlowComponent flow={flow} link={RouteFormatter.flowRoute(appName, applicationId, appFlowName, appFlowId)} />
        </Box>
      </Flex>
    );
  };

  renderFlows = (flows: Flow[]) => {
    const pageFlows = flows.slice(0, ITEMS_PER_PAGE);
    return pageFlows.length > 0 ? (
      <Box width={1} mb={5}>
        {pageFlows.map(this.renderFlow)}
      </Box>
    ) : (
      <Loader height="50vh" />
    );
  };

  renderAppFlowInfo = () => {
    const {
      match: {
        params: { appFlowName },
      },
    } = this.props;
    return (
      <Box width={1} pl={[0, LEFT_BANNER_WIDTH]} mb="56px">
        <Text as="h1" sx={{ ...theme.styles.h1 }}>
          {_.capitalize(appFlowName)}
        </Text>
      </Box>
    );
  };

  renderActionBox = () => (
    <Box width={1} pl={[0, LEFT_BANNER_WIDTH]}>
      <ActionBoxContainer />
      <Box width={1} mt={80} mb={4}>
        <PoweredBy />
      </Box>
    </Box>
  );

  renderPageManager = () => (
    <Box width={1} mb={5} pl={LEFT_BANNER_WIDTH}>
      <PageManager
        pageNumber={this.getPageNumber()}
        onPageNumberChange={this.onPageNumberChange}
        itemsLength={this.getFlowsCount()}
      />
    </Box>
  );

  render = () => {
    const { flows } = this.props;
    return (
      <Box width={[95 / 100, 8 / 10]} m="auto" pt={6}>
        {this.renderAppFlowInfo()}
        {this.renderFlows(flows || [])}
        {this.renderPageManager()}
        {this.renderActionBox()}
      </Box>
    );
  };
}

const mapStateToProps = (state: AppState, props: InitProps) => {
  const {
    location: { pathname },
  } = props;
  // Here we don't extract appFlowName and pageNumber from params because this also runs server side
  const { appFlowName, pageNumber } = RouteParser.appFlowRoute(pathname);
  const request = generateQuery({
    appFlowName,
    ...getPageNumberParams(pageNumber),
  });
  return {
    flows: state.flowRequests[request],
    appFlowName,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFlows: async (params: GetFlowsParams) => dispatch(await ActionBuilder.getFlows(params)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppFlowNameContainer);
