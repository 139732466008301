import React from 'react';
import { Flex, Button, Text } from 'rebass';

import theme from '../../theme';
import { ITEMS_PER_PAGE } from '../../utils/constants';
import { scrollToTop } from '../../utils';
import { Pagination } from 'antd';

type Props = {
  pageNumber: number;
  onPageNumberChange: (newValue: number) => void;
  itemsPerPage?: number;
  itemsLength: number;
  maxDisplayedPages?: number;
};

type JumpPageType = 'jump-prev' | 'jump-next';
type PageType = 'page' | 'prev' | 'next' | JumpPageType;

const PageManager = (props: Props) => {
  const { pageNumber, onPageNumberChange, itemsPerPage = ITEMS_PER_PAGE, itemsLength } = props;
  if (itemsLength <= ITEMS_PER_PAGE) {
    return null;
  }
  const onChange = (pageNumber: number) => {
    scrollToTop();
    onPageNumberChange(pageNumber);
  };

  const renderPrevButton = () => {
    return (
      <Button
        height="110%"
        width="100%"
        mt="-2.5%"
        variant="secondary"
        onClick={() => onChange(pageNumber - 1)}
        sx={{
          display: 'block',
          color: theme.colors.midnightLighten20,
          ':hover': { color: theme.colors.primary },
          borderRadius: 1.75,
        }}
      >
        <Flex width={1} height="100%" justifyContent="space-between" alignItems="center">
          <Text
            px={1}
            mb={1}
            sx={{
              fontSize: 24,
              fontWeight: 'normal',
            }}
          >
            {'<'}
          </Text>
          <Text display={['none', 'block']}>Prev</Text>
        </Flex>
      </Button>
    );
  };

  const renderNextButton = () => {
    return (
      <Button
        height="110%"
        width="100%"
        mt="-2.5%"
        variant="secondary"
        onClick={() => onChange(pageNumber + 1)}
        sx={{
          display: 'block',
          color: theme.colors.midnightLighten20,
          ':hover': { color: theme.colors.primary },
          borderRadius: 1.75,
        }}
      >
        <Flex width={1} height="100%" justifyContent="space-between" alignItems="center">
          <Text display={['none', 'block']}>Next</Text>
          <Text
            px={1}
            mb={1}
            sx={{
              fontSize: 24,
              fontWeight: 'normal',
            }}
          >
            {'>'}
          </Text>
        </Flex>
      </Button>
    );
  };

  const renderJumpButton = (type: JumpPageType) => {
    const toPageNumber = type === 'jump-prev' ? pageNumber - itemsPerPage : pageNumber + itemsPerPage;
    return (
      <Button
        height="110%"
        width="110%"
        mt="-7.5%"
        variant="secondary"
        onClick={() => onChange(toPageNumber)}
        sx={{
          display: 'block',
          color: theme.colors.midnightLighten20,
          ':hover': { color: theme.colors.primary },
          borderRadius: 1.75,
        }}
        style={{ padding: 0 }}
      >
        <Flex width={1} height="100%" justifyContent="center" alignItems="center">
          {'...'}
        </Flex>
      </Button>
    );
  };

  const renderButton = (index: number) => (
    <Button
      key={index}
      width="115%"
      height="115%"
      mx={-10}
      mt={-20}
      variant={index === pageNumber ? 'primary' : 'secondary'}
      onClick={index !== pageNumber ? () => onChange(index) : undefined}
      sx={{
        color: index === pageNumber ? 'white' : 'midnightLighten20',
        ':hover': { color: index === pageNumber ? 'white' : 'primary' },
        borderRadius: 1.75,
      }}
      style={{ padding: 0 }}
    >
      <Flex width={1} height="100%" justifyContent="center" alignItems="center">
        {index}
      </Flex>
    </Button>
  );

  const renderItem = (page: number, type: PageType) => {
    switch (type) {
      case 'prev':
        return renderPrevButton();
      case 'page':
        return renderButton(page);
      case 'next':
        return renderNextButton();
      default:
        return renderJumpButton(type);
    }
  };

  return (
    <Flex width={1} justifyContent="center">
      <Flex>
        <Pagination
          hideOnSinglePage
          showSizeChanger={false}
          current={pageNumber}
          defaultPageSize={itemsPerPage}
          onChange={onChange}
          total={itemsLength}
          itemRender={renderItem}
          responsive
        />
      </Flex>
    </Flex>
  );
};

export default PageManager;
